<section class="hero-headermain">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12">
                <div class="header-title wow fadeInUp">
                    <h1> JazzCash.</h1>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="hero-intro">
    <div class="container">
        <div class="row pb-5">
            <div class="col-sm col-lg-12 col-sm-12 col-xs-12 wow fadeInLeft">
                <h3>
                    <div class="contact-address-box wow fadeInUp">
                        <div class="address-box">
                            <p class="pb-3"> Please Use following number to pay using JazzCash.</p>
                            <div class="address-img">
                                <span><img style="height: 40px;" src="assets/images/home/jazzcash.png" class="img-fluid"
                                        alt="" /></span> 0306-1377580
                            </div>
                            <p>After sending payment, please whatsapp message with proof of receipt</p>
                        </div>
                    </div>
                </h3>
            </div>
        </div>
    </div>
</section>