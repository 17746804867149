<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="header-title wow fadeInUp">
          <h1>Manage Alerts</h1>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- table -->

<section class="hero-filter">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="manage-table">
          <table class="table">
            <tbody>
              <tr>
                <td>1</td>
                <td>LHE ->  JED</td>
                <td>2/5 -> 10/5</td>
                <td><a href="javascript:void(0);">DELETE</a></td>
              </tr>
              <tr>
                <td>2</td>
                <td>LHE ->  JED</td>
                <td>2/5 -> 10/5</td>
                <td><a href="javascript:void(0);">DELETE</a></td>
              </tr>
              <tr>
                <td>3</td>
                <td>LHE ->  JED</td>
                <td>2/5 -> 10/5</td>
                <td><a href="javascript:void(0);">DELETE</a></td>
              </tr>
              <tr>
                <td>4</td>
                <td>LHE ->  JED</td>
                <td>2/5 -> 10/5</td>
                <td><a href="javascript:void(0);">DELETE</a></td>
              </tr>
              <tr>
                <td>5</td>
                <td>LHE ->  JED</td>
                <td>2/5 -> 10/5</td>
                <td><a href="javascript:void(0);">DELETE</a></td>
              </tr>
            </tbody>
          </table>
          <div class="alerts-btn">
            <a href="javascript:void(0);">Save</a>
            <a href="javascript:void(0);" class="cancel">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>