import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jazz-cash',
  templateUrl: './jazz-cash.component.html',
  styleUrls: ['./jazz-cash.component.scss']
})
export class JazzCashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
