import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CareersService } from '../services/careers.service';
import { ToasterService } from '../services/toastr-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
interface Job {
  id: number;
  jobTitle: string;
  jobType: string;
  jobLocation: string;
  jobDescription: string;
  jobShortDescription: string;
  isActive: boolean;
  createdOn: string;
  googleFormURL:string;
}
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  job: Job | undefined;
  safeJobDescription: SafeHtml | undefined;
  jobDescription:string;

  constructor(
    private route: ActivatedRoute,
    private careerService: CareersService,
    private toasterService: ToasterService,   
    private sanitizer: DomSanitizer 

  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const jobId = Number(params.get('id'));
      this.getJobDetail(jobId);
    });
  }
  
  getJobDetail(id:any) {
    this.careerService.getJobDetail(id).subscribe((response) => {
      if (response) {
        if (response.status === 'success') {
          if (response.data) {            
            this.job  = response.data;
            this.jobDescription = this.job.jobDescription;
          }
        } else {
          if (response.message) {
            this.toasterService.showError(response.message, 'Error');
          }
        }
      }
    });
  }
  openGoogleForm() {
    // Replace 'YOUR_GOOGLE_FORM_URL' with the actual URL of your Google Form
    const googleFormUrl = this.job.googleFormURL;
    window.open(googleFormUrl, '_blank');
  }
}

