import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-umrah-search-result',
  templateUrl: './umrah-search-result.component.html',
  styleUrls: ['./umrah-search-result.component.scss'],
})
export class UmrahSearchResultComponent implements OnInit {
  singlePicker: any;
  rangePicker: any;
  selectedPriceRange: string = '100-400'; // Default selected price range
  selectedPackage: string = 'All packages';
  selectedNumofdays: string = '1-7 ';
  priceNight: boolean;
  Package: boolean;
  mainImageSrc = '../../../assets/images/umrah/umrah1.jpg';
  Numofdays: boolean;
  displayPrice: string;
  displayPackage: string;
  displaydays: string;
  constructor(private router: Router,private metaService: Meta,private titleService: Title) {}
  ngOnInit(): void {
    this.selectedPriceRange = '100-400'; // Change this to your desired default price range
    this.selectedPackage = 'All packages';
    this.selectedNumofdays = '1-7 ';
    this.displayPrice = `Price Range: ${this.selectedPriceRange}`;
    this.displayPackage = `Package: ${this.selectedPackage}`;
    this.displaydays = `Number of Days: ${this.selectedNumofdays}`;
    // Initialize the displayText with default values
    this.setTitleAndMetaTags();
    document.addEventListener('click', (e) => {
      const ignoreIds = [
        'price-h2',
        'days-h2',
        'Package-h2',
        'departureDataH5',
      ];
      //@ts-ignore
      if (ignoreIds.includes(e.srcElement.id)) return;
      // this.sectionOpen=false;
      this.priceNight = false;
      this.Package = false;
      this.Numofdays = false;
    });
  }
  setTitleAndMetaTags() {
    this.titleService.setTitle('Best Umrah Packages From Pakistan - Mehman.PK');
    this.metaService.updateTag({
      name: 'description',
      content: 'Best Umrah Packages From Pakistan 2024, Experience seamless arrangements, exceptional accommodations, and spiritual guidance - Book Now'
    });
  }
  locationClick() {
    document.getElementById('locationContainer1').classList.toggle('select1');
    document.getElementById('txtFrom').focus();
  }
  searchResults() {
    this.router.navigate(['/hotel-search-results']);
  }
  selectPriceRange(priceRange: string) {
    this.selectedPriceRange = priceRange;
    this.displayPrice = `Price Range: ${this.selectedPriceRange}`;
    this.priceNight = false; // Close the Price Per Night section
  }
  selectPackage(Package: string) {
    this.selectedPackage = Package;
    this.displayPackage = `Package: ${this.selectedPackage}`;
    this.Package = false; // Close the Price Per Night section
  }
  selectdays(Numofdays: string) {
    this.selectedNumofdays = Numofdays;
    this.displaydays = `Number of Days: ${this.selectedNumofdays}`;
    this.Numofdays = false; // Close the Price Per Night section
  }

  nightopen() {
    this.priceNight = !this.priceNight;
    this.Numofdays = false;
    this.Package = false;
  }
  Packageopen() {
    this.Package = !this.Package;
    this.Numofdays = false;
    this.priceNight = false;
  }
  NumofdaysOpen() {
    this.Numofdays = !this.Numofdays;
    this.Package = false;
    this.priceNight = false;
  }
  packageDetails() {
    this.router.navigate(['/umrah/package-details']);
  }
}
