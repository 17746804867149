<section class="hero-headermain">
  <div class="container padd-right">
    <!-- <button  type="button">Modify</button> -->
    <!-- <div class="modifyButton text-white d-flex justify-content-between" *ngIf="isShow">
      
            <div class="modifyButton d-lg-none" *ngIf="locationResult">
              {{ originLocation }}
              <a><img src="assets/images/home/exchange.svg" class="img-fluid mx-2" alt="" /></a>{{ destinationLocation }}
              <a class="mx-1 py-2 text-white bg-success px-2 rounded">{{
                leaveDate | date : "MMMM d, y"
                }}</a>
            </div>
            <div class="modifyButton d-flex justify-content-end d-lg-none" (click)="showSearch()" *ngIf="!isdisplayed">
              <a>Modify</a>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen"
                viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
              </svg>
            </div>
          </div> -->
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div id="search-div" class="search-result-box wow fadeInUp d-xs-none">
          <div class="book-box">
            <ul>
              <li id="liLocation" data-trigger="manual" data-toggle="popover" data-placement="top"
                data-content="<span><i class='fa fa-exclamation-triangle'></i> Enter valid From airport</span>">
                <a (click)="locationClick()">
                  <div class="book-details book-details1" id="locationDataDiv">
                    <h5 id="departureDataH5">city,property name or location</h5>
                    <input id="departureDataInout" type="text" class="mehman-inputfiled" value="lahore" readonly />
                  </div>
                </a>

                <div class="inputfiled-formboxes" id="locationContainer1">
                  <div class="inputfiled-formbox">
                    <div class="input-search">
                      <input type="text" id="txtFrom" class="input-from" placeholder="From" />
                      <div class="seach-icon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <p class="recent-tag">Popular Cities</p>
                      </li>
                      <li role="option" style="cursor: pointer">
                        <div class="hrtlCenter">
                          <div class="seach-icon">
                            <i class="fa fa-plane" aria-hidden="true"></i>
                          </div>
                          <div class="recent-searches ml-1">
                            <p>Lahore, Pakistan</p>
                            <p class="blackText">
                              Alama Iqbal International Airport
                            </p>
                          </div>
                          <div class="searches-code">
                            <p>LHE</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dates" style="cursor: pointer" (click)="Packageopen()">
                <div class="book-details">
                  <h2 id="Package-h2">{{ displayPackage }}</h2>
                  <p></p>
                </div>
              </li>
              <div class="Package-container" *ngIf="Package">
                <div class="price-range" (click)="selectPackage('Economy')">
                  Economy package
                </div>
                <div class="price-range" (click)="selectPackage('3-star')">
                  3 star
                </div>
                <div class="price-range" (click)="selectPackage(' 4-star')">
                  4 star
                </div>
                <div class="price-range" (click)="selectPackage(' 5-star')">
                  5 star
                </div>
              </div>
              <li class="dates" style="cursor: pointer" (click)="NumofdaysOpen()">
                <div class="book-details">
                  <h2 id="days-h2">{{ displaydays }}</h2>
                  <p></p>
                </div>
              </li>
              <div class="days-container" *ngIf="Numofdays">
                <div class="price-range" (click)="selectdays('1-7')">
                  1 - 7 days
                </div>
                <div class="price-range" (click)="selectdays('8-14')">
                  8 - 14 days
                </div>
                <div class="price-range" (click)="selectdays('15-21')">
                  15 - 21 days
                </div>
                <div class="price-range" (click)="selectdays('22-28')">
                  22 - 28 days
                </div>
              </div>

              <li class="datess" style="cursor: pointer" (click)="nightopen()">
                <div class="book-details">
                  <!-- <h5 id="price-h5">Price Per Night</h5> -->
                  <h2 id="price-h2">{{ displayPrice }}</h2>
                  <p></p>
                </div>
              </li>
              <div class="priceRang-container" *ngIf="priceNight">
                <div class="price-range" (click)="selectPriceRange('100-400')">
                  100 - 400
                </div>
                <div class="price-range" (click)="selectPriceRange('400-600')">
                  400 - 600
                </div>
                <div class="price-range" (click)="selectPriceRange('600-1000')">
                  600 - 1000
                </div>
                <div class="price-range" (click)="selectPriceRange('1000-2000')">
                  1000 - 2000
                </div>
              </div>
              <li class="result-btn">
                <a (click)="searchResults()">SEARCH</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero-filter">
  <div class="container">
    <!-- <div class="row" *ngIf="!isLoaded">
          <div class="col-lg-12 col-sm-12 col-xs-12">
            <img class="flight-img" src="../../../assets/images/search/flights.gif" />
            <h1 class="text-center" style="padding-bottom: 150px">
              Hold on, we're fetching flights for you
            </h1>
          </div>
        </div> -->
    <!-- <div class="row" >
          <div class="col-lg-12 col-sm-12 col-xs-12">
            <div class="flt-details wow fadeIn">
              <div class="alert alert-danger col-lg-12 col-sm-12 col-xs-12" role="alert">
             
              </div>
            </div>
          </div>
        </div> -->
    <div class="row">
      <div class="col-lg-3 col-sm-12 col-xs-12">
        <div class="header-offer-box sort-filter wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Filter by</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <h5>Suggest for you</h5>

              <label class="containerlabel">
                New Deals First
                <input type="radio" id="new-deals-first" name="sort-by" value="NewDealsFirs" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Price Low to High
                <input type="radio" id="price-low-to-high" name="sort-by" value="PriceLow" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Price High to Low
                <input type="radio" id="price-high-to-low" name="sort-by" value="PriceHigh" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="flt-check">
              <h5>Distance from Masjid Nabawi</h5>

              <label class="containerlabel">
                All
                <input type="radio" id="distance-all" name="distance-from-haram" value="all" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Within 300m
                <input type="radio" id="distance-300m" name="distance-from-haram" value="300" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                300m to 600m
                <input type="radio" id="distance-600m" name="distance-from-haram" value="600" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                600m to 1Km
                <input type="radio" id="distance-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                More than 1Km
                <input type="radio" id="distance-more-than-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>

            </div>
            <div class="flt-check">

              <h5>Distance from Haram</h5>

              <label class="containerlabel">
                All
                <input type="radio" id="distance-all" name="distance-from-haram" value="all" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                Within 300m
                <input type="radio" id="distance-300m" name="distance-from-haram" value="300" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                300m to 600m
                <input type="radio" id="distance-600m" name="distance-from-haram" value="600" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                600m to 1Km
                <input type="radio" id="distance-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>

              <label class="containerlabel">
                More than 1Km
                <input type="radio" id="distance-more-than-1km" name="distance-from-haram" value="1000" />
                <span class="checkmark"></span>
              </label>

            </div>
           
          </div>
        </div>
      </div>
    
    <div class="col-lg-9 col-sm-12 col-xs-12">
      <ng-container>
        <div class="">
          <h1>Umrah: 37 properties found</h1>
          <div class="umrah-card row wow fadeIn" (click)="packageDetails()">
            <div class="col-lg-3 col-md-3 col-sm-12">
              <img class="img-main" [src]="mainImageSrc" />
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <h3 class="fw-500">Jabal Omar Marriott Hotel Makkah</h3>
              <div class="d-flex justify-content-between">
                <div>
                  <h4 class="fw-500">Makkah hotel</h4>
                  <span><i class="fa fa-building" aria-hidden="true"></i> From:
                    All Airport</span><br />
                  <span><i class="fa fa-sun-o" aria-hidden="true"></i> 5
                    Nights</span>
                  <br />
                  <span><i class="fa fa-road" aria-hidden="true"></i> 1800
                    meter</span>
                </div>
                <div>
                  <h4 class="fw-500">Makkah hotel</h4>
                  <span><i class="fa fa-building" aria-hidden="true"></i> From:
                    All Airport</span><br />
                  <span><i class="fa fa-sun-o" aria-hidden="true"></i> 5
                    Nights</span>
                  <br />
                  <span><i class="fa fa-road" aria-hidden="true"></i> 1800
                    meter</span>
                </div>
                <div></div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <h1 class="white"><small>from Rs </small>38,000</h1>
              <button class="Detials" style="cursor: pointer" (click)="packageDetails()">
               Book Now
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  </div>
</section>