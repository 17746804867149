import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/home/shared/home.service';
import { HolidaysService } from 'src/app/services/holidays.service';
import { ToasterService } from 'src/app/services/toastr-service';

@Component({
  selector: 'app-holidays-package-details',
  templateUrl: './holidays-package-details.component.html',
  styleUrls: ['./holidays-package-details.component.scss']
})
export class HolidaysPackageDetailsComponent implements OnInit {
  searchResults:any;
  visible: boolean = false;
  constructor( private holidaysService: HolidaysService,
    private toasterService: ToasterService,
    private homeService: HomeService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
          this.getPackageDetails(id);
      }
  });
  }
  dialogClosed(): void {
    
    this.visible = false;
   
  }
  opendialog(){
    this.visible = true;
  }
  getPackageDetails(Id: any) {
    this.holidaysService.getGetById(Id).subscribe((response) => {
      if (response && response.status === 'success') {
        console.log("response", response);
        this.searchResults = response.data;
      } else {
        this.toasterService.showError(
          'Data not found.',
          'Error'
        );
      }
    });
  }
}

