import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { UsersComponent } from './user/users.component';
import { TravelAgentComponent } from './travel-agent/travel-agent.component';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule, DatePipe } from '@angular/common';
import { BlogComponent } from './blog/blog.component';
import { OfferComponent } from './offer/offer.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogDetailPageComponent } from './blog/blog-detail-page/blog-detail-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OfferDetailComponent } from './offer/offer-detail/offer-detail.component';
import { BusinessUserComponent } from './business-user/business-user.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DataViewModule } from 'primeng/dataview';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { AccordionModule } from 'primeng/accordion';
import { OfferByAirlineComponent } from './offer/offer-by-airline/offer-by-airline.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { TravelAPIsComponent } from './travel-apis/travel-apis.component';
import { JazzCashComponent } from './jazz-cash/jazz-cash.component';
import { CareersComponent } from './careers/careers.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { RouterModule, Routes } from '@angular/router';
import { HotelDetailsComponent } from './hotel-details/hotel-details.component';
import { TabViewModule } from 'primeng/tabview';
import { GalleriaModule } from 'primeng/galleria';
import { DialogModule } from 'primeng/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import { UmrahSearchResultComponent } from './umrah/umrah-search-result/umrah-search-result.component';
import { UmrahPackageDetailsComponent } from './umrah/umrah-package-details/umrah-package-details.component';
import { NavigationService } from './services/navigation.service';
import { HolidaysSearchResultComponent } from './Holidays/holidays-search-result/holidays-search-result.component';
import { HolidaysPackageDetailsComponent } from './Holidays/holidays-package-details/holidays-package-details.component';

const routes: Routes = [
  // ... other routes
  { path: 'jobDetails/:jobTitle', component: JobDetailsComponent },
  // ... other routes
];

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    TravelAgentComponent,
    BlogComponent,
    OfferComponent,
    BlogDetailPageComponent,
    OfferDetailComponent,
    BusinessUserComponent,
    ContactUsComponent,
    OfferByAirlineComponent,
    FranchiseComponent,
    TravelAPIsComponent,
    JazzCashComponent,
    CareersComponent,
    JobDetailsComponent,
    HotelDetailsComponent,
    UmrahSearchResultComponent,
    UmrahPackageDetailsComponent,
    HolidaysSearchResultComponent,
    HolidaysPackageDetailsComponent,
  
    
   
   
   
    
    
  
   
  


  ],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DataViewModule,
    CardModule,
    MatAutocompleteModule,
    ButtonModule,
    DropdownModule,
    PanelModule,
    GalleriaModule,
    RippleModule,
    CarouselModule,
    AccordionModule,
    TabViewModule,
    DialogModule,
    MatExpansionModule,
    // RouterModule.forRoot(appRoutes),
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
    }),
    NgxPaginationModule,
  ],
  exports: [RouterModule],
  providers: [CookieService, DatePipe,NavigationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
