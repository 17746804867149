<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="header-title wow fadeInUp">
          <h1>Margins</h1>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="hero-filter">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="margin-table-box">
          <div class="booking-sort booking-sort-2">
            <div class="booking-filter">
              <ul>
                <li>
                  <div class="dropdown show">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      FILTER
                    </a>
                  
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown show">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Sort By
                    </a>
                  
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="book-searchbar">
              <input type="text" class="book-input" placeholder="Search...">
            </div>
          </div>

          <div class="margin-table">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Airline</th>
                  <th scope="col">Margin Type</th>
                  <th scope="col">Margin Account</th>
                  <th scope="col">Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name Name</td>
                  <td>
                    <div class="margin-type">
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Percentage">
                        <label for="Percentage"></label>
                        <span>Percentage</span>
                      </div>
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Fixed">
                        <label for="Fixed"></label>
                        <span>Fixed</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="margin-account">
                      <input type="text" class="account-input" placeholder="Amount*">
                    </div>
                  </td>
                  <td>
                    <div class="duration">
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="Start Date*">
                      </div>
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="End Date*">
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Name Name</td>
                  <td>
                    <div class="margin-type">
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Percentage">
                        <label for="Percentage"></label>
                        <span>Percentage</span>
                      </div>
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Fixed">
                        <label for="Fixed"></label>
                        <span>Fixed</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="margin-account">
                      <input type="text" class="account-input" placeholder="Amount*">
                    </div>
                  </td>
                  <td>
                    <div class="duration">
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="Start Date*">
                      </div>
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="End Date*">
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Name Name</td>
                  <td>
                    <div class="margin-type">
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Percentage">
                        <label for="Percentage"></label>
                        <span>Percentage</span>
                      </div>
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Fixed">
                        <label for="Fixed"></label>
                        <span>Fixed</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="margin-account">
                      <input type="text" class="account-input" placeholder="Amount*">
                    </div>
                  </td>
                  <td>
                    <div class="duration">
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="Start Date*">
                      </div>
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="End Date*">
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Name Name</td>
                  <td>
                    <div class="margin-type">
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Percentage">
                        <label for="Percentage"></label>
                        <span>Percentage</span>
                      </div>
                      <div class="tool-check fliht-check">
                        <input type="checkbox" class="tool-input" id="Fixed">
                        <label for="Fixed"></label>
                        <span>Fixed</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="margin-account">
                      <input type="text" class="account-input" placeholder="Amount*">
                    </div>
                  </td>
                  <td>
                    <div class="duration">
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="Start Date*">
                      </div>
                      <div class="margin-account">
                        <input type="text" class="account-input" placeholder="End Date*">
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="add-post">
              <a href="javascript:void(0);"><span><i class="fa fa-plus" aria-hidden="true"></i></span> Add New Margin</a>
            </div>

            <div class="view-blog-btn">
              <a href="javascript:void(0);">UPDATE & SAVE</a>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="search-navigation nav-col wow fadeIn">
          <ul>
            <li>
              <a href="javascript:void(0);" class="arrow arrow-left">
                <svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.70249 12.3014C9.09917 12.69 9.09917 13.32 8.70249 13.7086C8.30582 14.0971 7.66268 14.0971 7.266 13.7086L1.1715 7.73844C0.774823 7.34986 0.774823 6.71985 1.1715 6.33127L7.266 0.361138C7.66268 -0.0274428 8.30582 -0.0274429 8.70249 0.361137C9.09917 0.749717 9.09917 1.37973 8.70249 1.76831L3.32623 7.03485L8.70249 12.3014Z"/>
                </svg>                
              </a>
            </li>
            <li><a href="javascript:void(0);">Previous</a></li>
            <li><a href="javascript:void(0);">1</a></li>
            <li><a href="javascript:void(0);">2</a></li>
            <li><a href="javascript:void(0);">3</a></li>
            <li><a href="javascript:void(0);">4</a></li>
            <li><a href="javascript:void(0);">5</a></li>
            <li><a href="javascript:void(0);">6</a></li>
            <li><a href="javascript:void(0);">7</a></li>
            <li><a href="javascript:void(0);">...</a></li>
            <li><a href="javascript:void(0);">Next</a></li>
            <li>
              <a href="javascript:void(0);" class="arrow arrow-right">
                <svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.297507 1.69861C-0.0991689 1.31003 -0.0991689 0.680015 0.297507 0.291435C0.694182 -0.097145 1.33732 -0.097145 1.734 0.291435L7.8285 6.26156C8.22518 6.65014 8.22518 7.28015 7.8285 7.66873L1.734 13.6389C1.33732 14.0274 0.694182 14.0274 0.297507 13.6389C-0.0991687 13.2503 -0.0991687 12.6203 0.297507 12.2317L5.67377 6.96515L0.297507 1.69861Z"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>