import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import Lightpick from 'src/assets/js/lightpick.min.js';
import { TripType } from 'src/app/models/enums.model';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-shared-hotel-search-results',
  templateUrl: './shared-hotel-search-results.component.html',
  styleUrls: ['./shared-hotel-search-results.component.scss'],
})
export class SharedHotelSearchResultsComponent implements OnInit {
  list: any[] = [];
  singlePicker: any;
  rangePicker: any;
  checkIn: Date;
  checkOut: Date;
  isLoaded: boolean;
  tripType: TripType;
  returnDate?: Date;
  sectionOpen: boolean = false;
  selectedRoom: number = 1;
  selectedAdult: number = 1;
  responsiveOptions;
  mainImageSrc = '../../../assets/images/hotel/m4.jpg';
  selectedChildren: number = 0;
  numberOptions: number[] = [0, 1, 2, 3, 4, 5]; // Add more options if needed
  selectedPriceRange: string = '100-400'; // Default selected price range
  changesApplied: boolean = false;
  displayText: string = '';
  displayPrice: string;
  datePipe: any;
  selectedLeaveDate: Date;
  childOptions: number[] = [1, 2, 3, 4, 5]; // Number of child selectors
  childAgeOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; // Child age options
  selectedChildAges: number[] = []; // Array to hold selected ages for each child

  constructor(private router: Router,private metaService: Meta,private titleService: Title,) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }
  @Input() searchfilters: string;
  ngOnInit(): void {
    this.metaService.updateTag({ name: 'Book Your Hotel Online - Discounted Rates - Mehman.PK', content: 'Discover more than just a place to stay with Mehman.PK. We provide a diverse range of accommodation. Plan your entire trip with us. Reserve Your Hotel Now' });
   
    this.checkIn = moment().add(1, 'days').toDate();
    this.checkOut = moment().add(2, 'days').toDate();
   
    this.selectedRoom = 1; // Default room count
    this.selectedAdult = 3; // Default adult count
    this.selectedChildren = 0; // For example
    this.selectedChildAges = Array.from({ length: this.selectedChildren }, () => 1);
    
    this.updateChildSelectors();
   
    this.selectedPriceRange = '100-400'; // Change this to your desired default price range
    this.displayPrice = `Price Range: ${this.selectedPriceRange}`;
    // Initialize the displayText with default values
    this.displayText = `${this.selectedRoom} Rooms ${this.selectedAdult} Adults ${this.selectedChildren} Children`;
    document.addEventListener('click', (e) => {
      const ignoreIds = ['price-h5', 'price-h2'];
      //@ts-ignore
      if (ignoreIds.includes(e.srcElement.id)) return;
    });
    this.setTitleAndMetaTags();
  }
  setTitleAndMetaTags() {
    this.titleService.setTitle('Book Your Hotel Online - Discounted Rates - Mehman.PK    ');
    this.metaService.updateTag({
      name: 'description',
      content: 'Discover more than just a place to stay with Mehman.PK. We provide a diverse range of accommodation. Plan your entire trip with us. Reserve Your Hotel Now'
    });
  }
  checkInDatePicker(isInitial: boolean = false) {
    // if (this.rangePicker) {
    //   this.rangePicker.destroy();
    // } // destroy old picker object
    if (this.singlePicker) {
      this.singlePicker.destroy();
    } // destroy old picker object
    // if (this.returnDate) {
    //   this.returnDate = null;
    // }
    this.singlePicker = new Lightpick({
      field: document.getElementById('dpcheckIn'),
      numberOfMonths: 1,
      startDate: this.checkIn,
      minDate: moment().toDate(),
      onSelect: (date) => {
        this.checkIn = date;
        this.returnDate =
          this.tripType === TripType.RoundTrip
            ? moment(this.checkIn).add(1, 'days').toDate()
            : null;
      },
    });

    if (!isInitial) {
      this.singlePicker.show();
    }
  }
  changeMainImage(newImageSrc: string) {
    this.mainImageSrc = newImageSrc;
  }
  checkOutDatePicker(isInitial: boolean = false) {
    // if (this.rangePicker) {
    //   this.rangePicker.destroy();
    // } // destroy old picker object
    if (this.singlePicker) {
      this.singlePicker.destroy();
    } // destroy old picker object
    // if (this.returnDate) {
    //   this.returnDate = null;
    // }
    this.singlePicker = new Lightpick({
      field: document.getElementById('dpcheckOut'),
      numberOfMonths: 1,
      startDate: this.checkOut,
      minDate: moment().toDate(),
      onSelect: (date) => {
        this.checkOut = date;
        this.returnDate =
          this.tripType === TripType.RoundTrip
            ? moment(this.checkOut).add(2, 'days').toDate()
            : null;
      },
    });

    if (!isInitial) {
      this.singlePicker.show();
    }
  }
  RoomGuests() {
    this.sectionOpen = !this.sectionOpen;
  }
  openHotel() {
    this.router.navigate(['/hotel-details']);
  }
  searchResults() {
    this.router.navigate(['/hotel-search-results']);
  }
  applyChanges() {
    this.changesApplied = true;
    this.displayText = `${this.selectedRoom} Rooms ${this.selectedAdult} Adults ${this.selectedChildren} Children`;
    this.sectionOpen = false;
  }
  locationClick() {
    document.getElementsByClassName('select2')[0]?.classList.remove('select2');
    document.getElementById('locationContainer1').classList.toggle('select1');
    document.getElementById('txtFrom').focus();
  }
  updateChildSelectors() {
    // Update the selectedChildAges array based on the selected number of children
    this.selectedChildAges = Array.from({ length: this.selectedChildren }, () => 1);
  }
}
