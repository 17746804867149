<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="header-title wow fadeInUp">
          <h1>Manage Offers</h1>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="hero-filter">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="manage-table">
          <div class="booking-sort booking-sort-2">
            <div class="booking-filter">
              <ul>
                <li>
                  <div class="dropdown show">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      FILTER
                    </a>
                  
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown show">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Sort By
                    </a>
                  
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                      <a class="dropdown-item" href="#">FILTER</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="book-searchbar">
              <input type="text" class="book-input" placeholder="Search...">
            </div>
          </div>

          <div class="book-table-box">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Ref</th>
                  <th scope="col">Offer Title</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>000001</td>
                  <td>Lorem Ipsum dolar sit amait</td>
                  <td>01/01/2020</td>
                  <td>01/01/2020</td>  
                  <td>01/06/2020</td>
                  <td>PKR 10,000</td>   
                  <td>
                    <ul class="table-icons">
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/eye.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pause.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pencil.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/delete.png" class="img-fluid" alt=""></a></li>
                    </ul>
                  </td>                     
                </tr>

                <tr>
                  <td>000001</td>
                  <td>Lorem Ipsum dolar sit amait</td>
                  <td>01/01/2020</td>
                  <td>01/01/2020</td>  
                  <td>01/06/2020</td>
                  <td>PKR 10,000</td>   
                  <td>
                    <ul class="table-icons">
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/eye.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pause.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pencil.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/delete.png" class="img-fluid" alt=""></a></li>
                    </ul>
                  </td>                     
                </tr>

                <tr>
                  <td>000001</td>
                  <td>Lorem Ipsum dolar sit amait</td>
                  <td>01/01/2020</td>
                  <td>01/01/2020</td>  
                  <td>01/06/2020</td>
                  <td>PKR 10,000</td>   
                  <td>
                    <ul class="table-icons">
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/eye.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pause.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pencil.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/delete.png" class="img-fluid" alt=""></a></li>
                    </ul>
                  </td>                     
                </tr>

                <tr>
                  <td>000001</td>
                  <td>Lorem Ipsum dolar sit amait</td>
                  <td>01/01/2020</td>
                  <td>01/01/2020</td>  
                  <td>01/06/2020</td>
                  <td>PKR 10,000</td>   
                  <td>
                    <ul class="table-icons">
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/eye.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pause.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pencil.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/delete.png" class="img-fluid" alt=""></a></li>
                    </ul>
                  </td>                     
                </tr>

                <tr>
                  <td>000001</td>
                  <td>Lorem Ipsum dolar sit amait</td>
                  <td>01/01/2020</td>
                  <td>01/01/2020</td>  
                  <td>01/06/2020</td>
                  <td>PKR 10,000</td>   
                  <td>
                    <ul class="table-icons">
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/eye.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pause.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/pencil.png" class="img-fluid" alt=""></a></li>
                      <li><a href="javascript:void(0);"><img src="assets/images/visa/delete.png" class="img-fluid" alt=""></a></li>
                    </ul>
                  </td>                     
                </tr>
                
              </tbody>
            </table>

            <div class="add-post">
              <a href="javascript:void(0);"><span><i class="fa fa-plus" aria-hidden="true"></i></span> Add New offer</a>
            </div>

            <div class="view-blog-btn">
              <a href="javascript:void(0);">View Offers</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>