<!-- blog header -->
<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="header-title wow fadeInUp">
          <h1>Article</h1>
        </div>
      </div>
    </div>
  </div>
</section>



<!-- article -->

<section class="hero-contact hero-contact-2 ">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-sm-12 col-xs-12">
        <div class="article-box">
          <div class="article-banner wow fadeInUp">
            <img src="assets/images/article/image1.png" class="img-fluid" alt="">
          </div>
          <div class="article-title wow fadeInUp">
            <h2>Van trip: 3 days in San Sebastian, Spain</h2>
            <ul>
              <li><span><img src="assets/images/article/S.Banerjee.png" class="img-fluid" alt=""></span> S. Banerjee</li>
              <li><span><img src="assets/images/search/calender.png" class="img-fluid" alt=""></span> Mar 27, 20</li>
              <li><span><img src="assets/images/article/key.png" class="img-fluid" alt=""></span></li>
              <li><a href="javascript:void(0);">Travel</a></li>
              <li><a href="javascript:void(0);">Internation Flights</a></li>
            </ul>
          </div>
          <div class="article-cont wow fadeInUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed. Eiusmod tempor. incididu nt ut labore et dolore magna aliqua. Ut enim. ad minim veniam, uis nostrud exerc itation ullamco. Laboris nisi. ut aliquip ex ea commodo consequat. Duis aute irure dolr. inreprehen derit in voluptate velit esse cillum dolore. Eu fugiat nulla pariatur. Excep teur sint occaecat non proident, sunt in culpa qui officia deserunt mollit anim idlaborum. Sed ut persp iciatis unde omnis iste natus error sit. voluptatem accusantium doloremque laudantium, totam rem aperiam. Architecto beatae vitae dicta sunt explicabo.Eu fugiat nulla pariatur.</p>
            <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Lorem ipsum dolor sit amet, consectetur adipisicing elit sed. Eiusmod tempor. incididu nt ut labore et dolore magna aliqua. Ut enim. ad minim veniam, uis nostrud exerc itation ullamco. Laboris nisi. ut aliquip ex ea commodo consequat. Duis aute irure dolr. inreprehen derit in voluptate velit esse cillum dolore. Eu fugiat nulla pariatur.</p>
          </div>
          <div class="brand-cont wow fadeInUp">
            <p>“ BUILD A LIFESTYLE AROUND YOUR BRAND, AND THE AUDIENCE WILL FOLLOW.”</p>
          </div>
          <div class="article-cont wow fadeInUp">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed. Eiusmod tempor. incididu nt ut labore et dolore magna aliqua. Ut enim. ad minim veniam, uis nostrud exerc itation ullamco. Laboris nisi. ut aliquip ex ea commodo consequat. Duis aute irure dolr. inreprehen derit in voluptate velit esse cillum dolore. Eu fugiat nulla pariatur.</p>
            <p>Excep teur sint occaecat non proident, sunt in culpa qui officia deserunt mollit anim idlaborum. Sed ut persp iciatis unde omnis iste natus error sit. voluptatem accusantium doloremque laudantium, totam rem aperiam. Architecto beatae vitae dicta sunt explicabo.Eu fugiat nulla pariatur. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.Lorem ipsum dolor sit amet, consectetur adipisicing elit sed. Eiusmod tempor. incididu nt ut labore et dolore magna aliqua. Ut enim. ad minim veniam, uis nostrud exerc itation ullamco. Laboris nisi. ut aliquip ex ea commodo consequat. Duis aute irure dolr. inreprehen derit in voluptate velit esse cillum dolore. Eu fugiat nulla pariatur.</p>
          </div>
        </div>

        <div class="article-nav-box wow fadeInUp">
          <div class="article-nav">
            <div class="article-nav-img">
              <img src="assets/images/article/back.png" class="img-fluid" alt="">
            </div>
            <a href="javascript:void(0);" class="arrow-back">
              <div class="arrow"><i class="fa fa-angle-left" aria-hidden="true"></i></div>
              <div class="arrowcont">Back</div>
            </a>
          </div>
  
          <div class="article-nav article-nav-2">
            <div class="article-nav-img">
              <img src="assets/images/article/back.png" class="img-fluid" alt="">
            </div>
            <a href="javascript:void(0);" class="arrow-back">
              <div class="arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></div>
              <div class="arrowcont">Next</div>
            </a>
          </div>
        </div>

        <div class="alisha-box wow fadeIn">
          <div class="alisha-img">
            <img src="assets/images/article/alisha.png" class="img-fluid" alt="">
          </div>
          <div class="alisha-cont">
            <h5>Alisha Michaels</h5>
            <p>Lorem ipsum dolor sit amet mas, consect adipisicing elit sed. Eiusmod etrent te mporincidi du nt ut labore et dolore magna aliqua. Ut enim.</p>
          </div>
        </div>

        <div class="contact-box wow fadeInUp">
          <div class="contact-header">
            <h2>Post a comment</h2>
          </div>
          <div class="comment-box">
            <div class="comment-form">
              <form action="">
                <div class="travel-input-box">
                  <input type="text" class="travel-input" placeholder="First Name" required>
                  <input type="text" class="travel-input" placeholder="Middle Name" required>
                  <input type="text" class="travel-input" placeholder="Last Name" required>
                </div>
                <div class="travel-message">
                  <textarea class="travel-input-message" placeholder="Your Comments"></textarea>
                </div>

                <div class="agree-box agree-box-2">
                  <div class="agree-icon">
                    <div class="tool-check fliht-check">
                      <input type="checkbox" id="agree" class="tool-input">
                      <label for="agree"></label>
                    </div>
                  </div>
                  <div class="agree-cont">
                    <p>Save my name, email, and website in this browser for the next time I comment.</p>
                  </div>
                </div>
                <div class="book-submit-btn">
                  <a href="javascript:void(0);" >Submit</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-12 col-xs-12">
        <div class="search-box wow fadeInUp">
          <div class="search-title">
            <h2>Search</h2>
          </div>
          <div class="serchbar">
            <input type="text" class="search-input" placeholder="Search">
            <div class="search-icon">
              <img src="assets/images/blog/search.png" class="img-fluid" alt="">
            </div>
          </div>
        </div>
        <div class="contact-box">
          <div class="contact-header wow fadeInUp">
            <h2>Most Viewed</h2>
          </div>
          <div class="viewd-body">
            <div class="viewed-img wow fadeInUp">
              <img src="assets/images/blog/image13.jpg" class="img-fluid" alt="">
              <div class="viewed-cont">
                <h5>How to Train Your Kids for Travel So You Can Travel More</h5>
                <p>S. Banerjee / Mar 27, 20</p>
              </div>
              <div class="blog-btn View-btn">
                <a href="javascript:void(0);">Read More</a>
              </div>
            </div>

            <div class="viewed-img wow fadeInUp">
              <img src="assets/images/blog/image14.jpg" class="img-fluid" alt="">
              <div class="viewed-cont">
                <h5>How to Train Your Kids for Travel So You Can Travel More</h5>
                <p>S. Banerjee / Mar 27, 20</p>
              </div>
              <div class="blog-btn View-btn">
                <a href="javascript:void(0);">Read More</a>
              </div>
            </div>

            <div class="viewed-img wow fadeInUp">
              <img src="assets/images/blog/image15.jpg" class="img-fluid" alt="">
              <div class="viewed-cont">
                <h5>How to Train Your Kids for Travel So You Can Travel More</h5>
                <p>S. Banerjee / Mar 27, 20</p>
              </div>
              <div class="blog-btn View-btn">
                <a href="javascript:void(0);">Read More</a>
              </div>
            </div>

            <div class="viewed-img wow fadeInUp">
              <img src="assets/images/blog/image16.jpg" class="img-fluid" alt="">
              <div class="viewed-cont">
                <h5>How to Train Your Kids for Travel So You Can Travel More</h5>
                <p>S. Banerjee / Mar 27, 20</p>
              </div>
              <div class="blog-btn View-btn">
                <a href="javascript:void(0);">Read More</a>
              </div>
            </div>

          </div>
        </div>

        <div class="contact-box categories-box wow fadeInUp">
          <div class="contact-header">
            <h2>Categories</h2>
          </div>
          <div class="categories">
            <ul>
              <li><a href="javascript:void(0);">Travel</a></li>        
              <li><a href="javascript:void(0);">Hajj Packages</a></li>
              <li><a href="javascript:void(0);">Flights</a></li>
              <li><a href="javascript:void(0);">Visas</a></li>
              <li><a href="javascript:void(0);">Hotels</a></li>
              <li><a href="javascript:void(0);">Transport</a></li>
              <li><a href="javascript:void(0);">Greate Places</a></li>
              <li><a href="javascript:void(0);">Online Booking</a></li>
              <li><a href="javascript:void(0);">24/7 Online Support</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>