import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import Lightpick from 'src/assets/js/lightpick.min.js';
import { TripType } from 'src/app/models/enums.model';
import { Router } from '@angular/router';
import {MatExpansionModule} from '@angular/material/expansion';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-insurance-search-results',
  templateUrl: './insurance-search-results.component.html',
  styleUrls: ['./insurance-search-results.component.scss'],
  standalone: true,
  imports: [MatExpansionModule],
})
export class InsuranceSearchResultsComponent implements OnInit {
  DepartingOn: Date = new Date(); // Replace this with your actual date
  ReturningOn: Date = new Date(); // Replace this with your actual date
  panelOpenState = false;
  singlePicker: any;
  rangePicker: any;

  tripType: TripType;
  leaveDate: Date;
  returnDate?: Date;
  tripTypeEnum = TripType;
  activeSection: string = '';
  showPassengerSection: boolean = false;
  selectedOption: string = '';
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.DepartingOn = moment().add(1, 'days').toDate();
    this.ReturningOn = moment().add(2, 'days').toDate();

  
  
    // Initialize the displayText with default values
    // document.addEventListener('click', (e) => {
    //   const ignoreIds = ['price-h5', 'price-h2'];
    //   //@ts-ignore
    //   if (ignoreIds.includes(e.srcElement.id)) return;
    // });
  }
  formatDate(date: Date): string {
    return new DatePipe('en-US').transform(date, 'd MMM yy');
  }

  formatDayOfWeek(date: Date): string {
    return new DatePipe('en-US').transform(date, 'EEEE');
  }


  togglePassengerSection(): void {
    this.showPassengerSection = !this.showPassengerSection;
    this.selectedOption = ''; // Reset the selected option
  }

  selectOption(option: string, section: string): void {
    this.selectedOption = option;
  }
  DepartingOnDatePicker(isInitial: boolean = false) {
    // if (this.rangePicker) {
    //   this.rangePicker.destroy();
    // } // destroy old picker object
    if (this.singlePicker) {
      this.singlePicker.destroy();
    } // destroy old picker object
    // if (this.returnDate) {
    //   this.returnDate = null;
    // }
    this.singlePicker = new Lightpick({
      field: document.getElementById('dpDepartingOn'),
      numberOfMonths: 1,
      startDate: this.DepartingOn,
      minDate: moment().toDate(),
      onSelect: (date) => {
        this.DepartingOn = date;
        this.returnDate =
          this.tripType === TripType.RoundTrip
            ? moment(this.DepartingOn).add(1, 'days').toDate()
            : null;
      },
    });

    if (!isInitial) {
      this.singlePicker.show();
    }
  }
  ReturningOnDatePicker(isInitial: boolean = false) {
    // if (this.rangePicker) {
    //   this.rangePicker.destroy();
    // } // destroy old picker object
    if (this.singlePicker) {
      this.singlePicker.destroy();
    } // destroy old picker object
    // if (this.returnDate) {
    //   this.returnDate = null;
    // }
    this.singlePicker = new Lightpick({
      field: document.getElementById('dpReturningOn'),
      numberOfMonths: 1,
      startDate: this.ReturningOn,
      minDate: moment().toDate(),
      onSelect: (date) => {
        this.ReturningOn = date;
        this.returnDate =
          this.tripType === TripType.RoundTrip
            ? moment(this.ReturningOn).add(2, 'days').toDate()
            : null;
      },
    });

    if (!isInitial) {
      this.singlePicker.show();
    }
  }
  searchResults() {
    // this.router.navigate(['/checkout']);
  }
  buy(){
    this.router.navigate(['/checkout']);
  }
  locationClick() {
    document.getElementsByClassName('select2')[0]?.classList.remove('select2');
    document.getElementById('locationContainer1').classList.toggle('select1');
    document.getElementById('txtFrom').focus();
  }
}
