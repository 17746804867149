import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResultModel } from '../models/api-result.model';
import { ApiCallService } from './api-call.service';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  private getPayFastCredentialsUrl = `${environment.bookingApiUrl}/Booking/GetPayFastCredentials`;
  
  private adultPriceSource = new BehaviorSubject<number>(0);
  adultPrice$ = this.adultPriceSource.asObservable();

  private visaIdSource = new BehaviorSubject<number>(0); // Initialize with a default value
  visaId$ = this.visaIdSource.asObservable();

  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  constructor(
    private api: ApiCallService,
  
  ) {}
  sendData(data: any) {
    this.dataSubject.next(data);
  }
  getPayFastCredentials(): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      this.getPayFastCredentialsUrl,
      'Get Credentials',
      new ApiResultModel()
    );
  }
  setAdultPrice(price: number) {
    this.adultPriceSource.next(price);
  }

  setVisaId(visaId: number) {
    this.visaIdSource.next(visaId);
  }
}
