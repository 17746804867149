<header>
  <!-- <button *ngIf="isUserLoggedIn && isHome" class="btn btn-primary" id="menu-btn">
    <span (click)="openNav()">&#9776;</span>
  </button> -->
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" routerLink="/">
            <img src="assets/images/home/logo.webp" class="img-fluid" alt="mehman.pk logo" />
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto"></ul>
            <ul class="right-navbar right-navbar-second">
              <!-- <li><a href="javascript:void(0);"><span><img src="assets/images/home/mail.svg" class="img-fluid"
                      alt=""></span>{{email}}</a></li> -->
              <li *ngIf="!isMobileView">
                <ul class="social-link">
                  <li>
                    <a href="javascript:void(0);" (click)="onNavigate('https://www.facebook.com/mehman.pk')"><i
                        class="fa fa-facebook social-Icon" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="onNavigate('https://twitter.com/mehman_pk')"><i
                        class="fa fa-twitter social-Icon" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="
                        onNavigate(
                          'https://www.youtube.com/channel/UCatMGnX4bIudf0DPK04gYrA'
                        )
                      "><i class="fa fa-youtube social-Icon" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="
                        onNavigate('https://www.instagram.com/mehmanpk/')
                      "><i class="fa fa-instagram social-Icon" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="
                        onNavigate('https://www.linkedin.com/company/mehmanpk')
                      "><i class="fa fa-linkedin social-Icon" aria-hidden="true"></i></a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="https://wa.me/923254888818" target="_blank"><span><img src="assets/images/home/WhatsApp.svg"
                      class="img-fluid" width="20px" alt="whatsapp" /></span>{{ phone }}</a>
              </li>
              <!-- <li><a href="javascript:void(0);" [routerLink]="['/blog']" routerLinkActive="active"><span><img
                      src="assets/images/home/blog.svg" class="img-fluid" alt=""></span>Blog</a></li> -->
              <li>
                <a  [routerLink]="['/business-user/auth/home']" routerLinkActive="active"><span><img
                      src="assets/images/home/bussiness.svg" class="img-fluid" alt="" /></span>Business Sign Up</a>
              </li>
              <li>
                <div class="dropdown show">
                  <!-- <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Pages
                  </a> -->

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#" [routerLink]="['/about-us']" routerLinkActive="active">About
                      Us</a>
                    <a class="dropdown-item" href="#" [routerLink]="['/article']" routerLinkActive="active">Article</a>
                    <a class="dropdown-item" href="#" [routerLink]="['/blog']" routerLinkActive="active">Blog</a>
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/booking-details']" routerLinkActive="active">Booking details</a> -->
                    <a class="dropdown-item" href="#" [routerLink]="['/business-users/auth/home']"
                      routerLinkActive="active">Business users</a>
                      
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/contact-us']" routerLinkActive="active">Contact
                      Us</a> -->
                    <a class="dropdown-item" href="#" [routerLink]="['/discount']"
                      routerLinkActive="active">Discount</a>
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/login']" routerLinkActive="active">Login</a> -->
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/make-payment']" routerLinkActive="active">Make
                      Payment</a> -->
                    <a class="dropdown-item" href="#" [routerLink]="['/manage-alerts']" routerLinkActive="active">Manage
                      Alerts</a>
                    <a class="dropdown-item" href="#" [routerLink]="['/manage-blog']" routerLinkActive="active">Manage
                      Blog</a>
                    <a class="dropdown-item" href="#" [routerLink]="['/manage-offers']" routerLinkActive="active">Manage
                      Offers</a>
                    <a class="dropdown-item" href="#" [routerLink]="['/margins']" routerLinkActive="active">Margins</a>
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/my-booking']" routerLinkActive="active">My
                      Booking</a> -->
                    <a class="dropdown-item" href="#" [routerLink]="['/offers']" routerLinkActive="active">Offer</a>
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/review-your-booking']" routerLinkActive="active">Review you booking</a> -->
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/search-result']" routerLinkActive="active">Search
                      Result</a> -->
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/signup']" routerLinkActive="active">Signup</a> -->
                    <a class="dropdown-item" href="#" [routerLink]="['/travel-partner/auth/home']"
                      routerLinkActive="active">Travel Partners</a>
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/traveller-details']" routerLinkActive="active">Traveller details</a> -->
                    <a class="dropdown-item" href="#" [routerLink]="['/visa']" routerLinkActive="active">Visa</a>
                    <!-- <a class="dropdown-item" href="#" [routerLink]="['/users']" routerLinkActive="active">Manage
                      Users</a> -->
                  </div>
                </div>
              </li>
              <li *ngIf="!isUserLoggedIn">
                <!-- <a routerLink="/auth/signin"
                  ><span
                    ><img
                      src="assets/images/home/mail.svg"
                      class="img-fluid"
                      alt=""
                  /></span>
                  Sign Up/Sign In
                </a> -->
                <div class="dropdown show">
                  <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"><img src="assets/images/home/login.svg" width="16px" height="16px" />
                    Login
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#" routerLink="/auth/signin" routerLinkActive="active">Personal</a>
                    <!-- <a class="dropdown-item" href="#" routerLink="/travel-agent/auth/signin" routerLinkActive="active">Business</a> -->
                    <a class="dropdown-item" href="#" routerLink="/travel-partner/auth/signin" routerLinkActive="active">Partner</a>
                  </div>
                </div>
              </li>
              <li *ngIf="isUserLoggedIn">
                <div class="dropdown show">
                  <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span><img src="assets/images/home/profile.jpg" class="img-fluid" alt="" /></span>
                    {{ userName }}
                  </a>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <!--<a class="dropdown-item" href="#">Profile</a>-->
                    <a class="dropdown-item btn" *ngIf="role === 'User'" routerLink="/user/flight-bookings">User Panel</a>
                    <a class="dropdown-item btn" *ngIf="role === 'TravelAgent' || role === 'BusinessUser'"
                      routerLink="/travel-partner/search">Travel Partners Panel</a>
                    <a class="dropdown-item btn" (click)="logout()">Log out</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<div id="mySidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <a href="#">Bookings</a>
  <a href="#">Services</a>
  <a href="#">Clients</a>
  <a href="#">Contact</a>
</div>
