import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private lastRouteKey = 'lastRoute';

  constructor(private router: Router) {
    this.storeLastRoute();
  }

  /**
   * Stores the last route in localStorage whenever a navigation ends.
   */
  private storeLastRoute(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      localStorage.setItem(this.lastRouteKey, event.urlAfterRedirects);
      console.log('Stored last route:', event.urlAfterRedirects); // Debug log
    });
  }

  /**
   * Retrieves the last route from localStorage.
   * @returns {string} The last route URL or '/' if none is found.
   */
  public getLastRoute(): string {
    return localStorage.getItem(this.lastRouteKey) || '/';
  }

  /**
   * Clears the stored last route from localStorage.
   */
  public clearLastRoute(): void {
    localStorage.removeItem(this.lastRouteKey);
  }
}
