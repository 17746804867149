<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12 offer-padding">
        <div class="offer-banner wow fadeInUp">
          <img
            src="assets/images/article/offer-banner.png"
            style="width: 100%; height: 200px"
            class="cover"
            alt="Responsive image"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- offer  -->

<section class="hero-contact hero-offer">
  <div class="container">
    <div class="row">
      <div class="col-lg-2">
        <div class="contact-box categories-box wow fadeInUp mt-0">
          <div id="mySidenav" class="sidenav">
            <div class="contact-header">
              <h2>Categories</h2>
            </div>
            <div *ngFor="let category of categories">
              <a
                href="javascript:void(0);"
                (click)="getOffersByCategory(category.id)"
                >{{ category.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="col-lg-10">
          <div class="row card" *ngIf="offersList.length > 0">
            <div>
              <p-accordion>
                <p-accordionTab
                  header="{{ offerItem.key }}"
                  *ngFor="
                    let offerItem of airlineByOffers;
                    let indexOfelement = index
                  "
                  [selected]="indexOfelement == 0"
                  #groupval
                >
                  <p-carousel
                    [value]="offerItem.offersData"
                    [numVisible]="5"
                    [numScroll]="5"
                    [circular]="false"
                    [responsiveOptions]="responsiveOptions" [showIndicators]="false"
                  >
                    <ng-template let-offer pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="offer-img blog-small wow fadeInUp">
                            <div>
                              <img
                                src="{{
                                  offer.thumbnailImage
                                    ? getImage(offer.thumbnailImage)
                                    : 'assets/images/blog/image1.png'
                                }}"
                                style="width: 200px; height: 300px"
                                class="product-image"
                                alt=""
                              />
                            </div>
                            <!-- <div>
                              <div class="product-name">{{ offer.name }}</div>
                              <div class="product-description">
                                Book by
                                {{ offer.endDate | date: "dd/MM/yyyy" }}
                              </div>
                            </div> -->
                            <div style="text-align: center">
                              <p-button
                                icon="pi pi-shopping-cart"
                                (click)="openOfferDetail(offer.id)"
                                label="Book Now"
                              >
                              </p-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-carousel>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
          <div class="row offers-main-box" *ngIf="offersList.length == 0">
            <div class="col-md-12 not-avail-box">
              <h4 class="not-avail-text">Offers are not available</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card">
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <div class="row offers-main-box" *ngIf="offersList.length > 0">
            <div *ngFor="
                let offer of offersList
                  | paginate: { itemsPerPage: 9, currentPage: p }
              ">
              <div class="offer-img blog-small wow fadeInUp">
                <div>
                  <img src="{{offer.thumbnailImage?getImage(offer.thumbnailImage):'assets/images/blog/image1.png'}}"
                    style="width:200px;height:300px" class="contain" alt="" />
                </div>
                <div class="blog-cont">
                  <h5>{{ offer.name }}</h5>
                  <p>Book by {{ offer.createdOn | date: "dd/MM/yyyy" }}</p>
                </div>
                <div style="text-align: center;">
                  <p-button icon="pi pi-shopping-cart" [routerLink]="['/offer-detail', offer.id]" label="Book Now">
                  </p-button>
                </div>
              </div>
            </div>
          </div>
          <div class="row offers-main-box" *ngIf="offersList.length == 0">
            <div class="col-md-12 not-avail-box">
              <h4 class="not-avail-text">Offers are not available</h4>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="row mt-3">
      <div class="col-lg-3 col-sm-12 col-xs-12"></div>
      <div class="col-lg-9 col-sm-12 col-xs-12">
        <div class="search-navigation wow fadeIn">
          <pagination-controls *ngIf="offersList.length > 0" (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
    </div> -->
  </div>
</section>
