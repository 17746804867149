export const environment = {
  production: true,

  //apiUrl: 'http://localhost:3001/api',
  // adminApiUrl: 'http://51.104.32.212:8084/api',
  // searchApiUrl: 'http://51.104.32.212:8083/api',
  // bookingApiUrl: 'http://51.104.32.212:8082/api',
  // commonApiUrl: 'http://51.104.32.212:8081/api',
  // adminApiUrl: 'http://87.75.111.181:8084/api',
  // searchApiUrl: 'http://87.75.111.181:8083/api',
  // bookingApiUrl: 'http://87.75.111.181:8082/api',
  // commonApiUrl: 'http://87.75.111.181:8081/api',
  // Dev Server
  // adminApiUrl: 'http://192.168.1.64:8084/api',
  // searchApiUrl: 'http://192.168.1.64:8083/api',
  // bookingApiUrl: 'http://192.168.1.64:8082/api',
  // commonApiUrl: 'http://192.168.1.64:8081/api',

  //Azure Server
  // adminApiUrl: 'https://admin-bookingengine.azurewebsites.net/api',
  // searchApiUrl: 'https://flightsearchengine.azurewebsites.net/api',
  // bookingApiUrl: 'https://bookings-engine.azurewebsites.net/api',
  // commonApiUrl: 'https://common-bookingengine.azurewebsites.net/api',

  //Hostinger vps server
  adminApiUrl: 'https://www.mehman.pk/getwayapi/api/admin',
  searchApiUrl: 'https://www.mehman.pk/getwayapi/api/flightsearchengine',
  bookingApiUrl: 'https://www.mehman.pk/getwayapi/api/bookings',
  commonApiUrl: 'https://www.mehman.pk/getwayapi/api/common',


  successUrl: 'https://www.mehman.pk/visa-documents',
  failureUrl: 'https://www.mehman.pk/shared-payment', 
    //ionos vps server
    // adminApiUrl: 'http://194.164.18.239:8010/api/admin',
    // searchApiUrl: 'http://194.164.18.239:8010/api/flightsearchengine',
    // bookingApiUrl: 'http://194.164.18.239:8010/api/bookings',
    // commonApiUrl: 'http://194.164.18.239:8010/api/common',
};
