import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { ApiResultModel } from '../models/api-result.model';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {
  private getTripPackageUrl = environment.adminApiUrl + '/TripPackages/GetAll'
  private SearchTripPackagesUrl = environment.adminApiUrl + '/TripPackages/SearchTripPackages';
  private getByIdUrl = environment.adminApiUrl + '/TripPackages/GetById'

  private searchResults: any;
  constructor(private api: ApiCallService,private http: HttpClient) {}
  setSearchResults(results: any) {
    this.searchResults = results;
  }
  getTripPackages(): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      this.getTripPackageUrl,
      'Get TripPackages',
      new ApiResultModel()
    );
  }
  getGetById(id: any): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      `${this.getByIdUrl}?id=${id}`,
      'Get trip',
      new ApiResultModel()
    );
  }
  getSearchResults() {
    return this.searchResults;
  }
  searchtrip(TripPackages: any): Observable<ApiResultModel> {
    return this.api.post<ApiResultModel>(
      this.SearchTripPackagesUrl,
      TripPackages
    );
  }
 
}
