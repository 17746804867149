<header>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <nav class="navbar navbar-expand-lg navbar-light navbar-fixed-top bg-light">
          <a class="navbar-brand" routerLink="/">
             <img src="assets/images/home/logo.webp" class="img-fluid" alt="mehman.pk logo">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidenav"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <ul class="right-navbar right-navbar-second">
              <li *ngIf="!isUserLoggedIn">
                <a routerLink="/auth/signin"><span><img src="assets/images/home/mail.svg" class="img-fluid"
                      alt=""></span> Sign In</a>
              </li>
              <li *ngIf="isUserLoggedIn">
                <div class="dropdown show">
                  <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span><img src="assets/images/home/profile.jpg" class="img-fluid" alt=""></span> {{ userName }}
                  </a>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <!--<a class="dropdown-item" href="#">Profile</a>-->
                    <a class="dropdown-item btn" (click)="logout()">Log out</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
