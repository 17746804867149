import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { HotelsService } from '../services/hotels.service';

@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss']
})
export class HotelDetailsComponent implements OnInit {
 @ViewChild('carousel') carousel!: ElementRef;
  // @ViewChildren('section') sections!: QueryList<ElementRef>;
  images: any[] | undefined;  
  responsiveOptions: any[] | undefined;
  constructor(private hotelsService: HotelsService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.hotelsService.getImages().then((images) => (this.images = images));
    this.responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];
  }

  
}
