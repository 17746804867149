import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel-apis',
  templateUrl: './travel-apis.component.html',
  styleUrls: ['./travel-apis.component.scss']
})
export class TravelAPIsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
