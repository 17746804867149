import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResultModel } from '../models/api-result.model';
import { ApiCallService } from './api-call.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
type VisaPaymentType = {
  paymentType: number;
  visaInquiryId:number
}

@Injectable({
  providedIn: 'root',
})
export class VisaInquiryService {
  
  private visaVisaInquiryUrl = environment.adminApiUrl + '/VisaInquiry/Save';
  private visaProcessingUrl = environment.adminApiUrl + '/VisaProcessing/Save';
  private getPaymentRequestUrl = (obj: VisaPaymentType) => `${environment.adminApiUrl}/VisaInquiry/PaymentRequest?visaInquiryId=${obj.visaInquiryId}&paymentType=${obj.paymentType}`;
  private getVisaInquiryByIdiUrl = environment.adminApiUrl + '/VisaInquiry';
  private SavePayfastPaymentDetailsUrl = `${environment.adminApiUrl}/VisaInquiry/SavePayfastPaymentDetails`;
  private visaInquiryId = new BehaviorSubject<number>(0);
  InquiryId$ = this.visaInquiryId.asObservable();
  constructor(private api: ApiCallService,private http: HttpClient) {}

  visaProcessing(visaProcessing: any): Observable<ApiResultModel> {
    return this.api.post<ApiResultModel>(
      this.visaProcessingUrl,
      visaProcessing
    );
  }
 
 
  // visaProcessing(visaProcessing: any): Observable<ApiResultModel> {
  //   return this.api.post<ApiResultModel>(
  //     this.visaProcessingUrl,
  //     visaProcessing
  //   );
  // }
  // visaProcessing(formData: FormData, url: string): Observable<ApiResultModel> {
  //   return this.http.post<ApiResultModel>(url, formData);
  // }
  // visaProcessing(formData: FormData, apiUrl: string): Observable<ApiResultModel> {
  //   const authToken = localStorage.getItem('authToken'); // Assuming the token is stored in localStorage
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${authToken}`,
  //   });
  //   return this.http.post<ApiResultModel>(apiUrl, formData, { headers });
  // }

  setInquiryId(InquiryId: number) {
    this.visaInquiryId.next(InquiryId);
  }
  createVisaInquiry(visaInquiry: any): Observable<ApiResultModel> {
    return this.api.post<ApiResultModel>(
      this.visaVisaInquiryUrl,
      visaInquiry
    );
  }
  getVisaInquiryById(id: any) {
    return this.api.get<ApiResultModel>(
      this.getVisaInquiryByIdiUrl + '/GetById?id=' + id, 'Get Refund by id',
      new ApiResultModel()
    );
  }
  SavePayfastPaymentDetails(params: any): Observable<ApiResultModel> {
    return this.api.post<ApiResultModel>(
      this.SavePayfastPaymentDetailsUrl,
      params
    );
  }
  getPaymentRequest(obj: VisaPaymentType) {
    return this.api.get<ApiResultModel>(
      this.getPaymentRequestUrl(obj), 
      'Get Refund by id',
      new ApiResultModel()
    );
  }
}
