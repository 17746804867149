import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from 'src/app/models/const';
import { CommonService } from 'src/app/services/common.service';
import { FaqService } from 'src/app/services/faq.service';
import { MetaService } from 'src/app/services/meta.service';
import { VisaService } from 'src/app/services/visa.service';
import { FaqItem } from 'src/app/shared/mat-faq/module/faq.item';
import { VisaInquiryService } from '../../services/visa-inquiry.service';
import { PriceService } from 'src/app/services/price.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-visa-check',
  templateUrl: './visa-check.component.html',
  styleUrls: ['./visa-check.component.scss'],
  providers: [MetaService]
})
export class VisaCheckComponent implements OnInit {
  selectedCountry: any = '';
  country: any = '';
  phone: string;
  countries: any[];
  visaDeailsByCountry: any[];
  displayDialogBox: boolean;
  displayConfirmDialogBox: boolean;
  contactUsForm: FormGroup;
  submitted: boolean;
  isError: boolean;
  errMsg: string;
  faqs: FaqItem[] = [];
  countryTags: any;
 
  // Other properties...

  selectedAdultPrice: number = 0; // Initialize it
  visaId: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private visaService: VisaService,
    private visaInquiryService: VisaInquiryService,
    private formBuilder: FormBuilder,
    private faqService: FaqService,
    private priceService: PriceService,
    private commonService: CommonService,
    private meta: MetaService,
    private metaService: Meta,
    private titleService: Title
   
  ) {
    this.phone = Constant.phone;
    this.country = this.route.snapshot.params.country.replaceAll('-', ' ');
    this.displayDialogBox = false;
    this.displayConfirmDialogBox = false;
    this.isError = false;
  }

  ngOnInit(): void {
    this.getCountries();
    this.getVisaDetailsByCountries(this.country);
    if (this.country) {
      this.getCountryTags(this.country);
    }
    this.getFaqByCountries(this.country);
    this.setTitleAndMetaTags();
  }
  setTitleAndMetaTags() {
    this.titleService.setTitle('Book Your Study | Visit Visa Online - Mehman.PK');
    this.metaService.updateTag({
      name: 'description',
      content: 'Plan your International journey with ease. Mehman.PK offers Study, Visit, Umrah Visas and more. #1 Online Travel Agency in Pakistan - Start Your Journey Now.'
    });
  }
  getCountries() {
    this.visaService.getVisaCountry().subscribe((response) => {
      this.countries = response.data;
    });
  }

  getVisaDetailsByCountries(country: any) {
    if (country !== undefined) {
      this.visaService
        .getVisaDetailsByCountry(country)
        .subscribe((response) => {
         
          this.visaDeailsByCountry = response.data;
          console.log("this.visaDeailsByCountry",this.visaDeailsByCountry)

        });
    }
  }

  getFaqByCountries(country: any) {
    if (country !== undefined) {
      this.faqs = [];
      this.faqService.getFaqByCountry(country).subscribe((response) => {
        if (response.data !== undefined && response.data !== null) {
          for (let i = 0; i <= response.data.length - 1; i++) {
            this.faqs.push({
              question: response.data[i].faqName,
              answer: response.data[i].faqValue,
            });
          }
        }
      });
    }
  }

  getVisaByCountry() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/visas/' + this.selectedCountry.name.replaceAll(' ', '-')])
    );
    window.open(url, '_blank');
  }

  // applyVisa(adultPrice: number) {
  //   // Ensure that adultPrice is a number
  //   if (typeof adultPrice === 'number') {
  //     // Navigate to the VisaDetailsComponent with the selected adult price
  //     this.router.navigate(['/visa-check/visa-details'], {
  //       queryParams: { adultPrice: adultPrice.toString() }
  //     });
  //   } else {
  //     // Handle the case where adultPrice is not a valid number
  //     console.error('Invalid adultPrice:', adultPrice);
  //   }
  // }

  applyVisa(adultPrice: number) {
    if (typeof adultPrice === 'number') {
      if (this.visaDeailsByCountry && this.visaDeailsByCountry.length > 0) {
        const visaId = this.visaDeailsByCountry[0].id; // Accessing the Id property from the object
        // Proceed with setting the adult price and navigating, using visaId as needed
        this.priceService.setAdultPrice(adultPrice); // Send adultPrice to the service
        this.priceService.setVisaId(visaId); // Update visaId in PriceService
        console.log("visaId:", visaId); // Make sure visaId is logged correctly
        this.router.navigate(['/visa-check/visa-details'], {
          queryParams: { adultPrice: adultPrice.toString(), visaId: visaId }
        });
      } else {
        console.error('No visa details available for the selected country');
      }
    } else {
      console.error('Invalid adultPrice:', adultPrice);
    }
  }
  
  
  
  closePopup() {
    this.displayConfirmDialogBox = false;
  }


  getCountryTags(countryName: string) {
    this.commonService.getCountryTags(countryName).subscribe(response => {
      if (response) {
        this.meta.updateTitle(response.data.title);
        response.data.tags.forEach(tag => {
          this.meta.updateMetaInfo(tag);
        })
      }
    });
  }
}