<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="header-title wow fadeInUp">
          <h1>Apply now and become a member of our team!</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<main>
  <section class="job-listings">
    <div class="job-item" *ngFor="let job of jobs">
      <h2 class="job-title">Job Title: <span>{{ job.jobTitle }}</span></h2>
      <p class="job-location">
        <i class="fa fa-map-marker mr-3" aria-hidden="true"></i>{{ job.jobLocation }}
      </p>
      <p class="job-location">
        <i class="fa fa-users mr-2" aria-hidden="true"></i>{{ job.jobType }}
      </p>
      <p class="job-description">{{ job.jobShortDescription }}</p> <!-- Adding short description -->
      <button (click)="navigateToJobDetails(job.id)">Read More</button> <!-- Pass job ID -->
    </div>
  </section>
</main>




