import { Component, Input, OnInit } from '@angular/core';
import { PriceService } from 'src/app/services/price.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/shared/auth.service';
import { ToasterService } from 'src/app/services/toastr-service';
import { VisaInquiryService } from 'src/app/services/visa-inquiry.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-payment',
  templateUrl: './shared-payment.component.html',
  styleUrls: ['./shared-payment.component.scss']
})
export class SharedPaymentComponent implements OnInit {

  @Input() adultPrice: number; // Define the 'adultPrice' input property

  isError: boolean;
  errMsg: string;
  submitted: boolean;
  data: any;
  selectedIndex = 0;
  formData: FormData;
  receivedData: any;
  visaInquiryId: any;
  payFastData: any;
  isAcceptedTermsAndConditions: boolean;

  constructor(
    private priceService: PriceService,
    private router: Router,
    private toasterService: ToasterService,
    private visaInquiryService: VisaInquiryService,
  ) {}

  ngOnInit(): void {
    this.submitted = false;
    this.isAcceptedTermsAndConditions = false;
    window.scrollTo(0, 0);

    this.visaInquiryService.InquiryId$.subscribe((InquiryId: number) => {
      this.visaInquiryId = InquiryId;
      console.log("visaInquiryId", this.visaInquiryId);
    });

    this.priceService.adultPrice$.subscribe((adultPrice: number) => {
      this.adultPrice = adultPrice;
      console.log("price", this.adultPrice);
    });

    this.priceService.data$.subscribe(data => {
      this.receivedData = data;
    });
    console.log("this.receivedData", this.receivedData);
    this.data = this.receivedData;
  }

  confirmBooking(payOption: any) {
    // Check if terms and conditions are accepted
    // if (!this.isAcceptedTermsAndConditions) {
    //   this.toasterService.showError('Please accept the terms and conditions.', 'Error');
    //   return;
    // }

    // Define payment type based on payOption
    let paymentType: number;
    switch (payOption) {
      case 'BankAccount':
        paymentType = 5;
        break;
      case 'Debit/Credit':
        paymentType = 3;
        break;
      case 'PayFast':
        paymentType = 6;
        break;
      // case 'BankTransfer':
      //   paymentType = 5;
      //   break;
      // case 'PayFast':
      //   paymentType = 6;
        // break;
      default:
        // Handle invalid payment option
        this.toasterService.showError('Invalid payment option selected.', 'Error');
        return;
    }

    const obj = {
      paymentType: paymentType,
      visaInquiryId: this.visaInquiryId
    };

    this.visaInquiryService.setInquiryId(this.visaInquiryId);

    this.visaInquiryService.getPaymentRequest(obj).subscribe((response) => {
      if (response && response.status === 'success') {
        console.log("response", response);
        let modifiedPaymentType: number;
        switch (paymentType) {
          case 5:
            modifiedPaymentType = 1;
            break;
          case 3:
            modifiedPaymentType = 3;
            break;
          case 6:
            modifiedPaymentType = 4;
            break;
          default:
            modifiedPaymentType = paymentType; // Just in case, use the original
        }
  
          this.submitForm(modifiedPaymentType);
        
      } else {
        if (response.message && response.message !== null && response.message !== '') {
          this.isError = true;
          this.errMsg = response.message;
          this.toasterService.showError(this.errMsg, 'Error');
        }
      }
    });
  }

  submitForm(paymentType: number) {
    this.priceService.getPayFastCredentials().subscribe((response) => {
      if (response) {
        console.log("response", response);
        this.payFastData = response;
        console.log("paymentType",paymentType)
          this.createPaymentForm(this.payFastData, paymentType);
      } else {
        this.toasterService.showError('Failed to get credentials', 'Error');
      }
    });
  }

  createPaymentForm(payFastData: any, paymentType: number): void {
    console.log("payFastData,paymentType",payFastData,paymentType)
    const basketId = Date.now().toString(); // Generate a timestamp-based ID for basket ID

    const form = document.createElement('form');
    form.id = 'PayFast_payment_form';
    form.name = 'PayFast-payment-form';
    form.method = 'post';
    form.action = `${payFastData.apiEndpoint}/PostTransaction`; // Set your action URL here

    const fields = [
      { name: 'CURRENCY_CODE', value: 'PKR' },
      { name: 'MERCHANT_ID', value: payFastData.merchantId },
      { name: 'TOKEN', value: payFastData.accessToken }, // Add your token value here
      // { name: 'SUCCESS_URL', value: 'environment.successUrl '}, // Use environment variable here
      { name: 'SUCCESS_URL', value: environment.successUrl},
      { name: 'FAILURE_URL', value: environment.failureUrl },
      { name: 'CHECKOUT_URL', value: 'https://typedwebhook.' },
      { name: 'CUSTOMER_EMAIL_ADDRESS', value: 'some-email@example.com' },
      { name: 'CUSTOMER_MOBILE_NO', value: '03441322765' },
       { name: 'TXNAMT', value: this.adultPrice },
        // { name: 'TXNAMT', value: 1},
      { name: 'BASKET_ID', value: basketId },
      { name: 'ORDER_DATE', value: new Date().toISOString() },
      { name: 'SIGNATURE', value: 'SOME-RANDOM-STRING' },
      { name: 'VERSION', value: 'MERCHANT-CART-0.1' },
      { name: 'TXNDESC', value: 'Item Purchased from Cart' },
      { name: 'PROCCODE', value: '00' },
      { name: 'TRAN_TYPE', value: 'ECOMM_PURCHASE'},
      { name: 'STORE_ID', value: '' },
      { name: 'RECURRING_TXN', value: 'true' },
      // { name: 'TRANSACTION_INSTRUMENT', value:'' }
      { name: 'TRANSACTION_INSTRUMENT', value:paymentType.toString() }
    ];

    // Create input elements for each field and append to the form
    fields.forEach(field => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = field.name;
      input.value = field.value;
      form.appendChild(input);
    });

    // Append form to body and submit
    document.body.appendChild(form);
    form.submit();
  }
}
