<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="header-title visa-header-title wow fadeInUp">
          <h1>Business Users</h1>
        </div>
        <div class="visa-box">
          <input type="text" class="visa-input" placeholder="Start Saving now">
          <div class="visa-btn">
            <a href="javascript:void(0);" data-toggle="modal" data-target="#exampleModalCenter">Sign Up</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- <app-middle></app-middle> -->


<section class="hero-saviours">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="support-box wow fadeInUp">
          <div class="support-img">
            <img src="assets/images/home/support.png" class="img-fluid" alt="24/7 customer support">
          </div>
          <div class="support-cont">
            <h5>24/7 Support</h5>
            <p>Offering 24/7 customer support</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="support-box wow fadeInUp">
          <div class="support-img">
            <img src="assets/images/home/online.png" class="img-fluid" alt="">
          </div>
          <div class="support-cont">
            <h5>Online Booking</h5>
            <p>Offering 24/7 customer support</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="support-box wow fadeInUp">
          <div class="support-img">
            <img src="assets/images/home/location.png" class="img-fluid" alt="">
          </div>
          <div class="support-cont">
            <h5>Great Places</h5>
            <p>Offering 24/7 customer support</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-xs-12">
        <div class="support-box wow fadeInUp">
          <div class="support-img">
            <img src="assets/images/home/card.png" class="img-fluid" alt="">
          </div>
          <div class="support-cont">
            <h5>Credit</h5>
            <p>Offering 24/7 customer support</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<!-- testimonials -->


<section class="hero-offers hero-testimonials">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12 wow fadeInUp">
        <div class="ellips">
          <img src="assets/images/visa/Faces.png" class="img-fluid" alt="">
        </div>
        <div class="testimonial-title">
          <h5>Testimonials</h5>
        </div>
        <div class="owl-carousel owl-theme ss_carousel" id="slider4">
          <div class="item">
            <div class="testimonial-box">
              <div class="testimonial-img">
                <img src="assets/images/visa/testimonial-img.png" class="img-fluid" alt="">
              </div>
              <div class="testimonial-cont">
                <h2>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</h2>
                <h5><span>Kerry Johnes</span> - Some Company</h5>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-box">
              <div class="testimonial-img">
                <img src="assets/images/visa/testimonial-img.png" class="img-fluid" alt="">
              </div>
              <div class="testimonial-cont">
                <h2>Lectus arcu bibendum at varius. Adipiscing diam donec adipiscing tristique.</h2>
                <h5><span>Kerry Johnes</span> - Some Company</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>







<!-- sign up -->
<div class="modal sign-popup fade" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">SIGN UP</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="Travel-sigup">
          <form action="">
            <label for="">Name</label>
            <input type="text" class="signup-input" placeholder="Name Name" required>

            <label for="">Phone / Mobile Number</label>
            <input type="text" class="signup-input" placeholder="Phone / Mobile Number" required>

            <label for="">Email Address</label>
            <input type="email" class="signup-input" placeholder="Email Address" required>

            <label for="">City</label>
            <input type="text" class="signup-input" placeholder="City" required>

            <label for="">Company Name</label>
            <input type="text" class="signup-input" placeholder="Company Name" required>

            <label for="">No. Of Employees</label>
            <input type="text" class="signup-input" placeholder="No. Of Employees" required>

            <div class="agree-box agree-box-3">
              <div class="agree-icon">
                <div class="tool-check fliht-check">
                  <input type="checkbox" id="agree" class="tool-input">
                  <label for="agree"></label>
                </div>
              </div>
              <div class="agree-cont">
                <p>I understand and agree with the <a href="javascript:void(0);">Privacy Policy</a>, <a
                    href="javascript:void(0);">User Agreement </a> and
                  <a href="javascript:void(0);">Terms of Services</a> of Mehman
                </p>
              </div>
            </div>
            <button type="submit" class="sign-btn">SIGN UP</button>
            <p class="sigmembr">Already a member? <a href="javascript:void(0);">Login</a></p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
