import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Observable } from 'rxjs';
import { ApiResultModel } from '../models/api-result.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CareersService {

  private getCarrersUrl = environment.adminApiUrl + '/Job/GetAll';
  private getJobDetailUrl = environment.adminApiUrl + '/Job/GetById';
  constructor(private api: ApiCallService) { }

  getCarrers(): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      this.getCarrersUrl,
      'Get Carrers',
      new ApiResultModel()
    );
  }

  getJobDetail(id: any): Observable<ApiResultModel> {
    return this.api.get<ApiResultModel>(
      `${this.getJobDetailUrl}?id=${id}`,
      'Get Job-Detail',
      new ApiResultModel()
    );
  }

}
