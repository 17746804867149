<section class="hero-headermain">
  <div class="container padd-right">
    <!-- <button  type="button">Modify</button> -->
    <!-- <div class="modifyButton text-white d-flex justify-content-between" *ngIf="isShow">
  
        <div class="modifyButton d-lg-none" *ngIf="locationResult">
          {{ originLocation }}
          <a><img src="assets/images/home/exchange.svg" class="img-fluid mx-2" alt="" /></a>{{ destinationLocation }}
          <a class="mx-1 py-2 text-white bg-success px-2 rounded">{{
            leaveDate | date : "MMMM d, y"
            }}</a>
        </div>
        <div class="modifyButton d-flex justify-content-end d-lg-none" (click)="showSearch()" *ngIf="!isdisplayed">
          <a>Modify</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen"
            viewBox="0 0 16 16">
            <path
              d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
          </svg>
        </div>
      </div> -->
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div id="search-div" class="search-result-box wow fadeInUp d-xs-none">
          <ul>
            <li
              id="liLocation"
              data-trigger="manual"
              data-toggle="popover"
              data-placement="top"
              data-content="<span><i class='fa fa-exclamation-triangle'></i> Enter valid From airport</span>"
            >
              <a (click)="locationClick()">
                <div class="book-details book-details1" id="locationDataDiv">
                  <h5 id="departureDataH5">city,property name or location</h5>
                  <input
                    id="departureDataInout"
                    type="text"
                    class="mehman-inputfiled"
                    value="lahore"
                    readonly
                  />
                  <!-- <p id="departureDataP">pakistan</p> -->
                </div>
              </a>

              <div class="inputfiled-formboxes" id="locationContainer1">
                <div class="inputfiled-formbox">
                  <div class="input-search">
                    <input
                      type="text"
                      id="txtFrom"
                      class="input-from"
                      placeholder="From"
                    />
                    <div class="seach-icon">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <p class="recent-tag">Popular Cities</p>
                    </li>
                    <li role="option" style="cursor: pointer">
                      <div class="hrtlCenter">
                        <div class="seach-icon">
                          <i class="fa fa-plane" aria-hidden="true"></i>
                        </div>
                        <div class="recent-searches ml-1">
                          <p>Lahore, Pakistan</p>
                          <p class="blackText">Alama Iqbal International Airport</p>
                        </div>
                        <div class="searches-code">
                          <p>LHE</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li
              class="dates"
              style="cursor: pointer"
              (click)="checkInDatePicker()"
            >
              <div class="book-details">
                <h5>CheckIn</h5>
                <h2>{{ checkIn | date : "d MMM yy" }}</h2>
                <!-- <p>{{ checkIn | date : "EEEE" }}</p> -->
                <div class="date">
                  <input type="text" name="daterange" id="dpcheckIn" readonly />
                </div>
              </div>
            </li>
            <li
              class="dates"
              style="cursor: pointer"
              (click)="checkOutDatePicker()"
            >
              <div class="book-details">
                <h5>CheckOut</h5>
                <h2>{{ checkOut | date : "d MMM yy" }}</h2>
                <!-- <p>{{ checkOut | date : "EEEE" }}</p> -->
                <div class="date">
                  <input
                    type="text"
                    name="daterange"
                    id="dpcheckOut"
                    readonly
                  />
                </div>
              </div>
            </li>
            <li class="datess" style="cursor: pointer" (click)="RoomGuests()">
              <div class="book-details">
                <h5 id="Room&Guests-h5">Room&Guests</h5>
                <h2 id="Room&Guests-h2">{{ displayText }}</h2>
              </div>
            </li>
            <div class="section-container" *ngIf="sectionOpen">
              <div class="d-flex">
                <div class="left-column">
                  <label for="room">Room</label>
                  <label for="adult">Adult</label>
                  <label for="children">Children</label>
                  <p>0 - 17 Years Old</p>
                </div>
                <div class="right-column">
                  <select id="room" [(ngModel)]="selectedRoom">
                    <option *ngFor="let option of numberOptions" [value]="option">{{ option }}</option>
                  </select>
                  <select id="adult" [(ngModel)]="selectedAdult">
                    <option *ngFor="let option of numberOptions" [value]="option">{{ option }}</option>
                  </select>
                  <!-- ... Other selectors ... -->
                  <select id="children" [(ngModel)]="selectedChildren" (change)="updateChildSelectors()">
                    <option [value]="0">0</option>
                    <option *ngFor="let option of numberOptions" [value]="option">{{ option }}</option>
                  </select>
                 
               
                  
                </div>
              </div>
              <hr class="Childrenhr">
              <p>Please provide right number of children along with their right age for best options and prices</p>
                 <!-- Display child selectors based on selectedChildren -->
                 <hr class="Childrenhr">
                 <div *ngIf="selectedChildren > 0" class="child-selectors d-flex justify-content-between">
                  <div *ngFor="let childAge of selectedChildAges; let i = index" class="child-selector">
                    <label for="child{{ i + 1 }}">Child {{ i + 1 }} Age:</label>
                    <select [id]="'child' + (i + 1)" [(ngModel)]="selectedChildAges[i]">
                      <option *ngFor="let ageOption of childAgeOptions" [value]="ageOption">{{ ageOption }}</option>
                    </select>
                  </div>

                </div>
                <button class="apply-button" (click)="applyChanges()">Apply</button>
            </div>

            <li class="result-btn">
              <a (click)="searchResults()">SEARCH</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="hero-filter">
  <div class="container">
    <!-- <div class="row" *ngIf="!isLoaded">
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <img class="flight-img" src="../../../assets/images/search/flights.gif" />
          <h1 class="text-center" style="padding-bottom: 150px">
            Hold on, we're fetching flights for you
          </h1>
        </div>
      </div> -->
    <!-- <div class="row" >
        <div class="col-lg-12 col-sm-12 col-xs-12">
          <div class="flt-details wow fadeIn">
            <div class="alert alert-danger col-lg-12 col-sm-12 col-xs-12" role="alert">
           
            </div>
          </div>
        </div>
      </div> -->
    <div class="row">
      <div class="col-lg-3 col-sm-12 col-xs-12">
        <div class="header-offer-box sort-filter wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Filter by</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <h5>Suggest for you</h5>
              <label class="containerlabel">
                last minutes deal
                <input type="radio" id="last-minutes-deal" name="arrange-by" value="lowest_fare"
                />
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                5 star
                <input type="radio" id="5-star" name="arrange-by" value="5_star"
                 />
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                Resort
                <input type="radio" id="resort" name="arrange-by" value="resort"
                 />
                <span class="checkmark"></span>
              </label>
            </div>
            
          </div>
        </div>
        <div class="header-offer-box sort-filter Arrenge-box wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Price Per Night</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <label class="containerlabel">
                0-100 PKR
                <input type="radio" id="price-range-0-100" name="price-range" value="0-100"
                       />
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                100-200 PKR
                <input type="radio" id="price-range-100-200" name="price-range" value="100-200"
                       />
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                200-300 PKR
                <input type="radio" id="price-range-200-300" name="price-range" value="200-300"
                       />
                <span class="checkmark"></span>
              </label>
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-sm-12 col-xs-12">
        <ng-container>
          <!-- <div class="flt-details wow fadeIn">
            <div
              class="alert alert-danger col-lg-12 col-sm-12 col-xs-12"
              role="alert"
            >
              No results found!
            </div>
          </div> -->
        </ng-container>
        <ng-container>
          <div class="row">
            <h1>Showing Property in lahore</h1>
            <div class="hotl-details wow fadeIn" (click)="openHotel()">
              <div class="hotel-logo">
                <img class="flight-img-main" [src]="mainImageSrc" />
                <div class="small-images">
                  <img
                    class="small-img"
                    src="../../../assets/images/hotel/m1.jpg"
                    (mouseover)="
                      changeMainImage('../../../assets/images/hotel/m1.jpg')
                    "
                  />
                  <img
                    class="small-img"
                    src="../../../assets/images/hotel/m3.jpg"
                    (mouseover)="
                      changeMainImage('../../../assets/images/hotel/m3.jpg')
                    "
                  />
                  <img
                    class="small-img"
                    src="../../../assets/images/hotel/m2.jpg"
                    (mouseover)="
                      changeMainImage('../../../assets/images/hotel/m2.jpg')
                    "
                  />
                  <img
                    class="small-img"
                    src="../../../assets/images/hotel/m3.jpg"
                    (mouseover)="
                      changeMainImage('../../../assets/images/hotel/m3.jpg')
                    "
                  />
                </div>
              </div>

              <div class="hotl-cont">
                <h2>Pimalai Resort & Spa, Thailand</h2>
              </div>
              <div class="hotl-pkr">
                <h5>200pks</h5>
                <p>Per Night</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
