<section class="hero-headermain">
  <div class="container padd-right">
    <!-- <button  type="button">Modify</button> -->
    <!-- <div class="modifyButton text-white d-flex justify-content-between" *ngIf="isShow">
    
          <div class="modifyButton d-lg-none" *ngIf="locationResult">
            {{ originLocation }}
            <a><img src="assets/images/home/exchange.svg" class="img-fluid mx-2" alt="" /></a>{{ destinationLocation }}
            <a class="mx-1 py-2 text-white bg-success px-2 rounded">{{
              leaveDate | date : "MMMM d, y"
              }}</a>
          </div>
          <div class="modifyButton d-flex justify-content-end d-lg-none" (click)="showSearch()" *ngIf="!isdisplayed">
            <a>Modify</a>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen"
              viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
            </svg>
          </div>
        </div> -->
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div id="search-div" class="search-result-box wow fadeInUp d-xs-none">
          <div class="book-box">
            <ul>
              <li
                id="liLocation"
                data-trigger="manual"
                data-toggle="popover"
                data-placement="top"
                data-content="<span><i class='fa fa-exclamation-triangle'></i> Enter valid From airport</span>"
              >
                <a (click)="locationClick()">
                  <div class="book-details book-details1" id="locationDataDiv">
                    <h5 id="departureDataH5">Countries travelling to</h5>
                    <input
                      id="departureDataInout"
                      type="text"
                      class="mehman-inputfiled"
                      value="lahore"
                      readonly
                    />
                  </div>
                </a>

                <div class="inputfiled-formboxes" id="locationContainer1">
                  <div class="inputfiled-formbox">
                    <div class="input-search">
                      <input
                        type="text"
                        id="txtFrom"
                        class="input-from"
                        placeholder="From"
                      />
                      <div class="seach-icon">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <p class="recent-tag">Popular Cities</p>
                      </li>
                      <li role="option" style="cursor: pointer">
                        <div class="hrtlCenter">
                          <div class="seach-icon">
                            <i class="fa fa-plane" aria-hidden="true"></i>
                          </div>
                          <div class="recent-searches ml-1">
                            <p>Lahore, Pakistan</p>
                            <p class="blackText">Alama Iqbal International Airport</p>
                          </div>
                          <div class="searches-code">
                            <p>LHE</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                class="dates"
                style="cursor: pointer"
                (click)="DepartingOnDatePicker()"
              >
                <div class="book-details">
                  <h5>DepartingOn</h5>
                  <h2>{{ formatDate(DepartingOn) }}</h2>

                  <div class="date">
                    <input
                      type="text"
                      name="daterange"
                      id="dpDepartingOn"
                      readonly
                    />
                  </div>
                </div>
              </li>
              <li
                class="dates"
                style="cursor: pointer"
                (click)="ReturningOnDatePicker()"
              >
                <div class="book-details">
                  <h5>ReturningOn</h5>
                  <h2>{{ formatDate(ReturningOn) }}</h2>

                  <div class="date">
                    <input
                      type="text"
                      name="daterange"
                      id="dpReturningOn"
                      readonly
                    />
                  </div>
                </div>
              </li>
             
             
              <li class="dates" style="cursor: pointer" (click)="togglePassengerSection()">
                <div class="book-details">
                  <h5></h5>
                  <h2>Passenger Traveling</h2>
                  <p></p>
                </div>
              </li>
              <li class="result-btn">
                <a (click)="searchResults()">SEARCH</a>
              </li>
            </ul>
            <div class="passenger-section" *ngIf="showPassengerSection">
              <button class="" (click)="selectOption('individual', 'passenger')">
                <i class="fa fa-user" aria-hidden="true"></i>
                Individual
              </button>
              
              <button class="" (click)="selectOption('family', 'passenger')">
                <i class="fa-solid fa-family"></i>
                Family
              </button>
              
              <div *ngIf="selectedOption === 'individual'" class="individual-option">
                <div class="dob-section">
                  <h3>Date of Birth</h3>
                  <input type="date">
                </div>
                
              </div>
              <div *ngIf="selectedOption === 'family'" class="family-option">
               
                <p>
                  <!-- Family content here -->
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
        
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="hero-filter">
  <div class="container">
    <!-- <div class="row" *ngIf="!isLoaded">
          <div class="col-lg-12 col-sm-12 col-xs-12">
            <img class="flight-img" src="../../../assets/images/search/flights.gif" />
            <h1 class="text-center" style="padding-bottom: 150px">
              Hold on, we're fetching flights for you
            </h1>
          </div>
        </div> -->
    <!-- <div class="row" >
          <div class="col-lg-12 col-sm-12 col-xs-12">
            <div class="flt-details wow fadeIn">
              <div class="alert alert-danger col-lg-12 col-sm-12 col-xs-12" role="alert">
             
              </div>
            </div>
          </div>
        </div> -->
    <div class="row">
      <div class="col-lg-3 col-sm-12 col-xs-12">
        <div class="header-offer-box sort-filter wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Filter by</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <h5>Choose Brand</h5>
              <label class="containerlabel">
                <input type="radio" id="adamjee" name="insurance-brand" value="Adamjee Insurance Company" />
                Adamjee Insurance Company
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                <input type="radio" id="habib" name="insurance-brand" value="Habib Insurance" />
                Habib Insurance
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                <input type="radio" id="pak-qatar" name="insurance-brand" value="Pak Qatar General Takaful" />
                Pak Qatar General Takaful
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                <input type="radio" id="united-general" name="insurance-brand" value="United General Insurance" />
                United General Insurance
                <span class="checkmark"></span>
              </label>
            </div>
            
          </div>
        </div>
        <div class="header-offer-box sort-filter Arrenge-box wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Medical Coverage</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <label class="containerlabel">
                <input type="radio" id="all" name="salary-range" value="all" />
                All
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                <input type="radio" id="50k-75k" name="salary-range" value="50k-75k" />
                $50,000 - $75,000
                <span class="checkmark"></span>
              </label>
            
              <label class="containerlabel">
                <input type="radio" id="75k-1lac" name="salary-range" value="75k-1lac" />
                $75,000 - $1 Lac
                <span class="checkmark"></span>
              </label>
            </div>
            
          </div>
        </div>
        <div class="header-offer-box sort-filter Arrenge-box wow fadeInUp">
          <div class="offer-header">
            <div class="offer-title offer-title-2">
              <h5>Covid Coverage</h5>
            </div>
          </div>
          <div class="filter-cont">
            <div class="flt-check">
              <label class="containerlabel">
                <input type="radio" id="yes" name="yes-no" value="Yes" />
                Yes
                <span class="checkmark"></span>
              </label>
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-sm-12 col-xs-12">
        <ng-container>
          <!-- <div class="flt-details wow fadeIn">
              <div
                class="alert alert-danger col-lg-12 col-sm-12 col-xs-12"
                role="alert"
              >
                No results found!
              </div>
            </div> -->
        </ng-container>
        <ng-container>
          <div class="row">
            <h1>Showing All Plans</h1>
            <div class="insurance-details row wow fadeIn" >
              <div class="insurance-logo col-lg-3 col-md-3 col-sm-12">
                <img
                  class=""
                  src="../../../assets/images/insurance/insurance-logo.jpeg"
                />
              </div>

              <div class="col-lg-5 col-md-4 col-sm-12">
                <div class="insurance-card">
                  <div class="block">
                    <p>Medical cover:</p>
                    <h4>$50,000</h4>
                  </div>
                  <div class="block">
                    <p>Coverage Upto:</p>
                    <h4>7 days</h4>
                  </div>
                  <div class="block">
                    <p>Loss of Baggage:</p>
                    <h4>$400</h4>
                  </div>
                </div>
                <div class="insurance-card mt-3">
                  <div class="block">
                    <p>Delayed Departure:</p>
                    <h4>$300</h4>
                  </div>
                  <div class="block">
                    <p>Covid-19:</p>
                    <h4>$50000 ($100 Deductible)</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="insurance-pkr">
                  <h5>Rs. 2,530</h5>
                  <h4>Premium</h4>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="Enquire">Enquire Now</button>
                  <button class="Buy" (click)="buy()" style="cursor: pointer">Buy</button>
                </div>
              </div>

              <mat-accordion class="w-100">
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="justify-content-center">
                      More Details
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                      <h4 class="clr-green">Medical Benefits:</h4>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-12">
                      <div class="insurance-card">
                        <div class="block">
                          <p>Medical Expenses & Hospitalization</p>
                          <h4>$50,000</h4>
                        </div>
                        <div class="block">
                          <p>Repatriation in case of illness/injury:</p>
                          <h4>On Actuals</h4>
                        </div>
                        <div class="block">
                          <p>Delivery of medicine:</p>
                          <h4>On Actuals</h4>
                        </div>
                      </div>
                      <div class="insurance-card">
                        <div class="block">
                          <p>Emergency Dental care:</p>
                          <h4>$600 (Excess of $60 applies)</h4>
                        </div>
                        <div class="block">
                          <p>Repatriation of mortal remains:</p>
                          <h4>On Actuals</h4>
                        </div>
                        <div class="block">
                          <p>Return of dependant children:</p>
                          <h4>On Actuals</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
