<div *ngIf="job">
  <section class="hero-headermain">
    <div class="container">
      <div class="row">
        <div class="Welcome col-lg-12 col-sm-12 col-xs-12">
          <div class="header-title wow fadeInUp">
            <h1 class="job-title">{{ job.jobTitle }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="job-details-container">
    <div class="job-details">
      <div class="job-description">
        <h2>Job Description</h2>
        <div [innerHTML]="jobDescription"></div>
      </div>
      <!-- <div class="responsibilities">
        <h2>Responsibilities</h2>
        <ul>
          <li>
            Meets with clients to determine travel needs, budgets, and
            preferences
          </li>
          <li>
            Sells and coordinates transportation, accommodations, insurance,
            tours, and activities
          </li>
          <li>
            Advises clients regarding destinations, weather, and activities
          </li>
        </ul>
      </div>
      <div class="offer">
        <h2>What We Offer</h2>
        <ul>
          <li>Market Competitive Salary</li>
          <li>Medical coverage for Self & Dependents</li>
          <li>Company Stocks Sharing Plans</li>
        </ul>
        <div class="apply-button mt-4">
          <button (click)="openGoogleForm()">Apply Now</button>
        </div>
      </div> -->
      <div class="apply-button mt-4">
        <button (click)="openGoogleForm()">Apply Now</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!job">
  <p>Loading...</p>
</div>
