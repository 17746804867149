<div class="container">
  <div class="row justify-content-between">
    <div class="col-md-8">
      <!-- <div class="carousel" #carousel>
        <div class="slide">
          <img
            src="../../../assets/images/hotel/m1.jpg"
            alt="Image 1"
            class="carousel-img"
          />
        </div>
        <div class="slide">
          <img
            src="../../../assets/images/hotel/m2.jpg"
            alt="Image 2"
            class="carousel-img"
          />
        </div>
         Add more slides as needed -->
      <!-- <button class="prev-button" (click)="prevSlide()">&#9664;</button>
        <button class="next-button" (click)="nextSlide()">&#9654;</button>
      </div> -->
      <div class="card md:flex md:justify-content-center">
        <p-galleria
          [(value)]="images"
          [responsiveOptions]="responsiveOptions"
          [numVisible]="5"
        >
          <ng-template pTemplate="item" let-item>
            <img [src]="item.itemImageSrc" style="width: 100%" />
          </ng-template>
          <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
              <img [src]="item.thumbnailImageSrc" />
            </div>
          </ng-template>
        </p-galleria>
      </div>

      <div class="carousel-header">
        <h2>Avari Xpress Gulberg</h2>
        <p>
          <i class="fa fa-map-marker" aria-hidden="true"></i> Hotel Location
        </p>
      </div>

      <div class="appendTop15">
        <div class="prmLikedAmen">
          <h3 class="__heading">Popular Amenities</h3>
          <ul class="prmLikedAmen__list">
            <li class="prmLikedAmen__list--item">
              <span class="prmLikedAmen__list--itemIcon"
                ><img
                  class="w-100"
                  src="//promos.makemytrip.com/images/highlighted/free_wi_fi.png"
                  alt=""
                  title=""
              /></span>
              <p class="font14 lineHight16">Free Wi-Fi</p>
            </li>
            <li class="prmLikedAmen__list--item">
              <span
                class="prmLikedAmen__list--itemIcon amenitiesSprite amenitiesIcon defaultAmenIcon"
                ><i class="fa fa-check" aria-hidden="true"></i
              ></span>
              <p class="font14 lineHight16">Elevator/Lift</p>
            </li>
            <li class="prmLikedAmen__list--item">
              <span
                class="prmLikedAmen__list--itemIcon amenitiesSprite amenitiesIcon defaultAmenIcon"
                ><i class="fa fa-check" aria-hidden="true"></i
              ></span>
              <p class="font14 lineHight16">Newspaper</p>
            </li>
            <li class="prmLikedAmen__list--item">
              <span
                class="prmLikedAmen__list--itemIcon amenitiesSprite amenitiesIcon defaultAmenIcon"
                ><i class="fa fa-check" aria-hidden="true"></i
              ></span>
              <p class="font14 lineHight16">Laundry Service</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-4 border-radius">
      <div class="info-box">
        <h2>Superior Queen Room</h2>
        <div class="info-item">
          <p>Number of Adults:</p>
          <span>2</span>
        </div>
        <div class="info-item">
          <p>Price per Night:</p>
          <span>$150</span>
        </div>
        <div class="prmRoomDtlCard__footer p-3">
          <a class="prmRoomDtlCard__link">VIEW OTHER ROOMS</a
          ><span class="bookNowBtn"
            ><a class="primaryBtn">BOOK THIS NOW</a></span
          >
        </div>
      </div>
      <div class="baseRight__item appendTop10">
        <div class="altViewOnMap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.5382510918125!2d74.32915597481565!3d31.564284544972512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904d22df9c961%3A0x52d708e1b45ccf92!2sMehman.pk!5e0!3m2!1sen!2s!4v1692038371308!5m2!1sen!2s"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="prmRatingCard">
          <div class="mt-auto mb-auto">
            <p class="font12 appendTop3">Based on <b> 302 Ratings</b></p>
            <p class="prmRatingCard__cta">
              <a class="font14 latoBlack capText">Read all Reviews</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-2 mb-5">
    <p-tabView class="tabview">
      <p-tabPanel header="OVERVIEW">
        <h3 class="__heading">About The Hotel</h3>
        <p>
          Take advantage of recreation opportunities such as a 24-hour health
          club, or other amenities including complimentary wireless Internet
          access and concierge services.Enjoy a meal at the restaurant or snacks
          in the coffee shop/cafe. The hotel also offers 24-hour room
          service.Featured amenities include a 24-hour business center,
          complimentary newspapers in the lobby, and dry cleaning/laundry
          services. Planning an event in Lahore? This hotel has facilities
          measuring 6500 square feet (604 square meters), including conference
          space. Free valet parking is available onsite.Make yourself at home in
          one of the 210 air-conditioned rooms featuring minibars and
          flat-screen televisions.
        </p>
      </p-tabPanel>
      <p-tabPanel header="LOCATION">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.5382510918125!2d74.32915597481565!3d31.564284544972512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904d22df9c961%3A0x52d708e1b45ccf92!2sMehman.pk!5e0!3m2!1sen!2s!4v1692038371308!5m2!1sen!2s"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </p-tabPanel>
      <p-tabPanel header="AMENITIES">
        <h2>Amenities at Avari Xpress Gulberg</h2>
        <div class="AMENITIES">
          <p class="d-flex">
            <span class="fclty__header--iconWrapper appendRight12"
              ><img
                src="//promos.makemytrip.com/images/highlighted/free_wi_fi.png"
                alt="Free Wi-Fi"
                title="" /></span
            ><span class="latoBold font16 mt-auto mb-auto ml-3"
              >Free Wi-Fi</span
            >
          </p>
          <p class="d-flex">
            <span class="fclty__header--iconWrapper appendRight12"
              ><i class="fa fa-check" aria-hidden="true"></i></span
            ><span class="latoBold font16 mt-auto mb-auto ml-3"
              >Elevator/Lift</span
            >
          </p>
          <p class="d-flex">
            <span class="fclty__header--iconWrapper appendRight12"
              ><i class="fa fa-check" aria-hidden="true"></i></span
            ><span class="latoBold font16 mt-auto mb-auto ml-3">Newspaper</span>
          </p>
          <p class="d-flex">
            <span class="fclty__header--iconWrapper appendRight12"
              ><i class="fa fa-check" aria-hidden="true"></i></span
            ><span class="latoBold font16 mt-auto mb-auto ml-3"
              >Laundry Service</span
            >
          </p>
        </div>
        <div class="AMENITIES-section p-2">
          <div class="">
            <p class="font16">Highlighted Amenities</p>
            <ul>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Free Wi-Fi</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Elevator/Lift</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Newspaper</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Laundry Service</span>
              </li>
            </ul>
          </div>
          <div class="">
            <p class="font16">Basic Facilities</p>
            <ul>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Free Wi-Fi</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Elevator/Lift</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Newspaper</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Laundry Service</span>
              </li>
            </ul>
          </div>
          <div class="">
            <p class="font16">Payment Services</p>
            <ul>
              <li class="font14 lineHight22 appendBottom3">
                - <span>ATM</span>
              </li>
            </ul>
          </div>
          <div class="">
            <p class="font16">Health and wellness</p>
            <ul>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Gym</span>
              </li>
            </ul>
          </div>
          <div class="">
            <p class="font16">General Services</p>
            <ul>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Luggage Storage</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Concierge</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Multilingual Staff</span>
              </li>
              <li class="font14 lineHight22 appendBottom3">
                - <span>Ticket/Tour Assistance</span>
              </li>
            </ul>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="PROPERTY RULES">
        <div class="htlRules">
          <h2 class="font22 latoBlack blackText appendBottom3">
            Property Information
          </h2>
          <p class="font16">
            <span
              >Check-in:
              <span class="latoBlack appendRight5">2 PM - 11:30 PM</span></span
            ><span
              >Check-out: <span class="latoBlack appendLeft5">12 PM</span></span
            >
          </p>
          <p class="font16 lineHight20 appendTop15">
            <span class="latoBold show">Languages Spoken by Staff</span>
            <span>English</span>
          </p>
          <div class="htlRules__list grid1">
            <div class="htlRules__list--Col1">
              <div class="htlRules__list--item">
                <span class="greyDot appendRight10"></span>
                <div class="font14 lineHight18">
                  Please check detailed rules while reviewing your booking
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="REVIEW">
        <div class="container-fluid mx-auto">
          <div class="row">
            <div class="col-xl-7 col-lg-8 col-md-10 col-12 text-center mb-5">
              <div class="reviewcard">
                <div class="row d-flex">
                  <div class="">
                    <img
                      class="profile-pic"
                      src="https://i.imgur.com/V3ICjlm.jpg"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <h3 class="mt-2 mb-0">Mukesh Kumar</h3>
                    <div>
                      <p class="text-left">
                        <span class="text-muted">4.0</span>
                        <span class="fa fa-star star-active ml-3"></span>
                        <span class="fa fa-star star-active"></span>
                        <span class="fa fa-star star-active"></span>
                        <span class="fa fa-star star-active"></span>
                        <span class="fa fa-star star-inactive"></span>
                      </p>
                    </div>
                  </div>
                  <div class="ml-auto">
                    <p class="text-muted pt-5 pt-sm-3">10 Sept</p>
                  </div>
                </div>
                <div class="row text-left">
                  <h4 class="blue-text mt-3">
                    "An awesome activity to experience"
                  </h4>
                  <p class="content">
                    If you really enjoy spending your vacation 'on water' or
                    would like to try something new and exciting for the first
                    time.
                  </p>
                </div>
                <div class="row text-left">
                  <img class="pic" src="https://i.imgur.com/kjcZcfv.jpg" />
                  <img class="pic" src="https://i.imgur.com/SjBwAgs.jpg" />
                  <img class="pic" src="https://i.imgur.com/IgHpsBh.jpg" />
                </div>
                <div class="row text-left mt-4">
                  <div class="like mr-3 vote">
                    <img src="https://i.imgur.com/mHSQOaX.png" /><span
                      class="blue-text pl-2"
                      >20</span
                    >
                  </div>
                  <div class="unlike vote">
                    <img src="https://i.imgur.com/bFBO3J7.png" /><span
                      class="text-muted pl-2"
                      >4</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
