import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CareersService } from '../services/careers.service';
import { ToasterService } from '../services/toastr-service';



@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  jobs: any[] = [];

  constructor(
    private router: Router,  
    private careerService: CareersService,
    private toasterService: ToasterService,    
  ) { }

  ngOnInit(): void {
    this.getCareers();
    window.scrollTo(0, 0);
  }

  navigateToJobDetails(id: any): void {
   
    this.router.navigate(['/careers/jobDetails', id]);
  }
  
  getCareers() {
    this.careerService.getCarrers().subscribe((response) => {
      if (response) {
        if (response.status === 'success') {
          if (response.data) {
            this.jobs = [];
            this.jobs = response.data;
          }
        } else {
          if (response.message) {
            this.toasterService.showError(response.message, 'Error');
          }
        }
      }
    });
  }

}
