<section class="hero-headermain">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="review-booking-box wow fadeInUp">
          <div class="header-title booking-title">
            <h1>Payment</h1>
          </div>
          <div class="booking-rang-slider">
            <div class="slider-nmbr">
              <ul>
                <li class="check-icon">1</li>
                <li>
                  <img
                    src="assets/images/search/check.png"
                    class="img-fluid"
                    alt=""
                  />
                </li>
                <li>3</li>
              </ul>
            </div>
            <div class="rang-slider">
              <div class="inline-slider"></div>
            </div>
            <div class="slider-list">
              <ul>
                <li>Visa Details</li>
                <li>Payment</li>
                <li>Upload Document</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="hero-contact hero-contact-2">
  <div class="contact-box contact-makepayment p-5">
    <div class="contact-header wow fadeIn">
      <h2><b>Payment Methods</b></h2>
    </div>
    <div class="col-xs-12">
      <p-tabView>
        <p-tabPanel header="Bank Account">
          <ng-template pTemplate="content">
            <!-- <img
        src="assets/images/payfast/White.jpg"
        style="height: 93px;width: auto;"
        class="img-fluid "
        alt=""
      /> -->

            <div class="travel-form-box row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="control-label travel-form-box-lable"
                    ><strong>Price you pay</strong></label
                  >
                  <h2>
                    <span style="color: #57b957">{{
                      adultPrice | currency : "PKR" : "symbol" : "1.0-0"
                    }}</span>
                  </h2>
                </div>
              </div>
            </div>
            <!-- <div class="agree-box" style="margin: 0 18px">
        <div class="agree-icon">
          <div class="tool-check fliht-check">
            <input
              type="checkbox"
              id="agree"
              class="tool-input"
              [(ngModel)]="isAcceptedTermsAndConditions"
              [ngModelOptions]="{ standalone: true }"
            />
            <label for="agree"></label>
          </div>
        </div>
        <div class="agree-cont">
          <p>
            I understand and agree with the Fare Rules , the Visa Rules ,
            the Privacy Policy , the User Agreement and Terms of Service
            of
            <a href="javascript:void(0);"> Mehman.pk</a>
          </p>
        </div>
      </div> -->
            <div class="p-3">
              <button
                type="submit"
                class="paypro-submit-button btn"
                (click)="confirmBooking('BankAccount')"
              >
                Pay Now
              </button>
            </div>
            <div
              *ngIf="submitted && !isAcceptedTermsAndConditions"
              class="invalid-feedback"
              style="margin: 0 18px"
            >
              <div>Please accept terms & conditions.</div>
            </div>
          </ng-template>
        </p-tabPanel>
        <!-- <p-tabPanel
  header="Bank Transfer"
 
>
  <ng-template pTemplate="content">
    <div style="padding: 10px 20px">
      After clicking "Pay Now," you will promptly receive your PayPro ID
      via both Email and SMS. To complete your payment, follow these
      steps:<br />
      Open your banking application or visit your nearest Bank ATM.

      <ul style="list-style-type: disc; padding: 11px 30px">
        <li>Select the "Bill Payment" option.</li>
        <li>Choose the "PayPro Payment" option.</li>
        <li>Enter your PayPro ID. And make your payment.</li>
      </ul>

      Once your payment is confirmed, you will receive your flight
      ticket. If you encounter any issues during this process, please
      don't hesitate to reach out to us at 0325-4888818 or
      <span style="color: #4caf50">mehmanpk@gmail.com</span> for
      assistance<br />
      <div class="">
        <button
          type="submit"
          class="paypro-submit-button btn"
          (click)="confirmBooking('BankTransfer')"
        
        >
          Pay Now
        </button>
      </div>
      PayPro is available only on following banks only
    </div>

    <img
      src="assets/images/paypro/paypro.jpg"
      style="padding: 5px"
      class="img-fluid"
      alt="ATM"
    />

    <div class="travel-form-box row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label class="control-label travel-form-box-lable"
            ><strong>Price you pay</strong></label
          >
          <h2>
            <span style="color: #57b957">{{
              adultPrice | currency : "PKR" : "symbol" : "1.0-0"
            }}</span>
          </h2>
        </div>
      </div>
    </div>
     <div class="agree-box" style="margin: 0 18px">
      <div class="agree-icon">
        <div class="tool-check fliht-check">
          <input
            type="checkbox"
            id="agree"
            class="tool-input"
            [(ngModel)]="isAcceptedTermsAndConditions"
            [ngModelOptions]="{ standalone: true }"
          />
          <label for="agree"></label>
        </div>
      </div>
      <div class="agree-cont">
        <p>
          I understand and agree with the Fare Rules , the Visa Rules ,
          the Privacy Policy , the User Agreement and Terms of Service
          of
          <a href="javascript:void(0);"> Mehman.pk</a>
        </p>
      </div>
    </div>
    <div
      *ngIf="submitted && !isAcceptedTermsAndConditions"
      class="invalid-feedback"
      style="margin: 0 18px"
    >
      <div>Please accept terms & conditions.</div>
    </div> 
  </ng-template>
</p-tabPanel> -->
        <p-tabPanel header="PayFast Wallet">
          <ng-template pTemplate="content">
            <!-- <img
    src="assets/images/payfast/White.jpg"
    style="height: 93px;width: auto;"
    class="img-fluid "
    alt=""
  /> -->

            <div class="travel-form-box row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="control-label travel-form-box-lable"
                    ><strong>Price you pay</strong></label
                  >
                  <h2>
                    <span style="color: #57b957">{{
                      adultPrice | currency : "PKR" : "symbol" : "1.0-0"
                    }}</span>
                  </h2>
                </div>
              </div>
            </div>
            <!-- <div class="agree-box" style="margin: 0 18px">
    <div class="agree-icon">
      <div class="tool-check fliht-check">
        <input
          type="checkbox"
          id="agree"
          class="tool-input"
          [(ngModel)]="isAcceptedTermsAndConditions"
          [ngModelOptions]="{ standalone: true }"
        />
        <label for="agree"></label>
      </div>
    </div>
    <div class="agree-cont">
      <p>
        I understand and agree with the Fare Rules , the Visa Rules ,
        the Privacy Policy , the User Agreement and Terms of Service
        of
        <a href="javascript:void(0);"> Mehman.pk</a>
      </p>
    </div>
  </div> -->
            <div class="p-3">
              <button
                type="submit"
                class="paypro-submit-button btn"
                (click)="confirmBooking('PayFast')"
              >
                Pay Now
              </button>
            </div>
            <div
              *ngIf="submitted && !isAcceptedTermsAndConditions"
              class="invalid-feedback"
              style="margin: 0 18px"
            >
              <div>Please accept terms & conditions.</div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="Debit/Credit Card">
          <ng-template pTemplate="content">
            <!-- <img
          src="assets/images/payfast/White.jpg"
          style="height: 93px;width: auto;"
          class="img-fluid "
          alt=""
        /> -->

            <div class="travel-form-box row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="control-label travel-form-box-lable"
                    ><strong>Price you pay</strong></label
                  >
                  <h2>
                    <span style="color: #57b957">{{
                      adultPrice | currency : "PKR" : "symbol" : "1.0-0"
                    }}</span>
                  </h2>
                </div>
              </div>
            </div>
            <!-- <div class="agree-box" style="margin: 0 18px">
          <div class="agree-icon">
            <div class="tool-check fliht-check">
              <input
                type="checkbox"
                id="agree"
                class="tool-input"
                [(ngModel)]="isAcceptedTermsAndConditions"
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="agree"></label>
            </div>
          </div>
          <div class="agree-cont">
            <p>
              I understand and agree with the Fare Rules , the Visa Rules ,
              the Privacy Policy , the User Agreement and Terms of Service
              of
              <a href="javascript:void(0);"> Mehman.pk</a>
            </p>
          </div>
        </div> -->
            <div class="p-3">
              <button
                type="submit"
                class="paypro-submit-button btn"
                (click)="confirmBooking('Debit/Credit')"
              >
                Pay Now
              </button>
            </div>
            <div
              *ngIf="submitted && !isAcceptedTermsAndConditions"
              class="invalid-feedback"
              style="margin: 0 18px"
            >
              <div>Please accept terms & conditions.</div>
            </div>
          </ng-template>
        </p-tabPanel>

        <!-- <p-tabPanel
          header="BankTransfer"
          *ngIf="role != 'TravelAgent' && role != 'BusinessUser'"
        >
          <ng-template pTemplate="content">
            <div class="travel-form-box row">
              <div class="col-sm col-lg-12 col-sm-12 col-xs-12 wow fadeInLeft">
                <h2>
                  <div class="wow fadeInUp">
                    <div class="">
                      <div class="address-img">
                        <span
                          ><img
                            style="height: 40px"
                            src="assets/images/home/bank-icon.svg"
                            class="img-fluid"
                            alt="" /></span
                        >Meezan Bank
                      </div>
                      <div class="Bank-address-cont">
                        <p>
                          <strong> Branch:</strong> Garhi Shahu, Lahore
                          <br />
                          <strong> Name:</strong> Mehman Travel & Tours (Pvt)
                          Limited
                          <br />
                          <strong> Account Number:</strong> 02500107011082
                        </p>
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
              <div class="col-sm col-lg-12 col-sm-12 col-xs-12 wow fadeInLeft">
                <h2>
                  <div class="wow fadeInUp">
                    <div class="">
                      <div class="address-img">
                        <span
                          ><img
                            style="height: 40px"
                            src="assets/images/home/bank-icon.svg"
                            class="img-fluid"
                            alt="" /></span
                        >Summit Bank
                      </div>
                      <div class="Bank-address-cont">
                        <p>
                          <strong> Branch:</strong> Empress Road, Lahore
                          <br />
                          <strong> Name:</strong> Mehman Travel & Tours (Pvt)
                          Limited
                          <br />
                          <strong> Account Number:</strong>
                          1-3-14-20311-714-143071
                        </p>
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
              <div class="col-sm col-lg-12 col-sm-12 col-xs-12 wow fadeInLeft">
                <h2>
                  <div class="wow fadeInUp">
                    <div class="">
                      <div class="address-img">
                        <span
                          ><img
                            style="height: 40px"
                            src="assets/images/home/bank-icon.svg"
                            class="img-fluid"
                            alt="" /></span
                        >Standard Chartered
                      </div>
                      <div class="Bank-address-cont">
                        <p>
                          <strong> Branch:</strong> Gulberg, Lahore
                          <br />
                          <strong> Name:</strong> Mehman Travel & Tours (Pvt)
                          Limited
                          <br />
                          <strong> Account Number:</strong> 01700454901
                        </p>
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
              <div class="col-sm col-lg-12 col-sm-12 col-xs-12 wow fadeInLeft">
                <h2>
                  <div class="wow fadeInUp">
                    <div class="">
                      <div class="address-img">
                        <span
                          ><img
                            style="height: 40px"
                            src="assets/images/home/bank-icon.svg"
                            class="img-fluid"
                            alt="" /></span
                        >Bank Alfalah
                      </div>
                      <div class="Bank-address-cont">
                        <p>
                          <strong> Branch:</strong> Gulberg, Lahore
                          <br />
                          <strong> Name:</strong> Mehman Travel & Tours (Pvt)
                          Limited
                          <br />
                          <strong> Account Number:</strong> 00061008022116
                        </p>
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="control-label travel-form-box-lable"
                    ><strong>Price you pay</strong></label
                  >
                  <h2>
                    <span style="color: #57b957">{{
                      adultPrice | currency : "PKR" : "symbol" : "1.0-0"
                    }}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="agree-box" style="margin: 0 18px">
              <div class="agree-icon">
                <div class="tool-check fliht-check">
                  <input
                    type="checkbox"
                    id="agree"
                    class="tool-input"
                    [(ngModel)]="isAcceptedTermsAndConditions"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label for="agree"></label>
                </div>
              </div>
              <div class="agree-cont">
                <p>
                  I understand and agree with the Fare Rules , the Visa Rules ,
                  the Privacy Policy , the User Agreement and Terms of Service
                  of
                  <a href="javascript:void(0);"> Mehman.pk</a>
                </p>
              </div>
            </div>
            <div
              *ngIf="submitted && !isAcceptedTermsAndConditions"
              class="invalid-feedback"
              style="margin: 0 18px"
            >
              <div>Please accept terms & conditions.</div>
            </div>
            <div class="book-submit-btn">
              <button
                type="submit"
                class="custom-submit-button btn"
                (click)="confirmBooking('BankTransfer')"
                [disabled]="!isAcceptedTermsAndConditions || submitted"
              >
                Pay Now
              </button>
            </div>
          </ng-template>
        </p-tabPanel>
        <p-tabPanel
          header="JazzCash"
          *ngIf="role != 'TravelAgent' && role != 'BusinessUser'"
        >
          <ng-template pTemplate="content">
            <div class="travel-form-box row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <div class="jazzcash">
                    <p class="pb-3">
                      Please Use following number to pay using JazzCash.
                    </p>
                    <div class="address-img pb-3">
                      <span
                        ><img
                          style="height: 40px"
                          src="assets/images/home/jazzcash.png"
                          class="img-fluid"
                          alt=""
                      /></span>
                      <strong class="jazznum">0306-1377580</strong>
                    </div>
                    <p>
                      After sending payment, please whatsapp message with proof
                      of receipt
                    </p>
                  </div>
                  <label class="control-label travel-form-box-lable"
                    ><strong>Price you pay</strong></label
                  >
                  <h2>
                    <span style="color: #57b957">{{
                      adultPrice | currency : "PKR" : "symbol" : "1.0-0"
                    }}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="agree-box" style="margin: 0 18px">
              <div class="agree-icon">
                <div class="tool-check fliht-check">
                  <input
                    type="checkbox"
                    id="agree"
                    class="tool-input"
                    [(ngModel)]="isAcceptedTermsAndConditions"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label for="agree"></label>
                </div>
              </div>
              <div class="agree-cont">
                <p>
                  I understand and agree with the Fare Rules , the Visa Rules ,
                  the Privacy Policy , the User Agreement and Terms of Service
                  of
                  <a href="javascript:void(0);"> Mehman.pk</a>
                </p>
              </div>
            </div>
            <div
              *ngIf="submitted && !isAcceptedTermsAndConditions"
              class="invalid-feedback"
              style="margin: 0 18px"
            >
              <div>Please accept terms & conditions.</div>
            </div>
            <div class="book-submit-btn">
              <button
                type="submit"
                class="custom-submit-button btn"
                (click)="confirmBooking('JazzCash')"
                [disabled]="!isAcceptedTermsAndConditions || submitted"
              >
                Pay Now
              </button>
            </div>
          </ng-template>
        </p-tabPanel> -->
      </p-tabView>
    </div>
  </div>
</section>
